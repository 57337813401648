import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";
import { Form, Input, Button, Checkbox } from "antd";
import { useDispatch } from "react-redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory, useParams } from "react-router-dom";
import {
  dynamicRequest,
  useDynamicSelector,
  create_evaluator_mutation,
  get_roles_query,
} from "@services/redux";
import { useTranslation } from "react-i18next";
import { Box, HStack, Text, useBreakpointValue } from "native-base";

const evaluatorDetails = (props) => {
  const { t } = useTranslation();
  const { initialValues, close } = props;
  const [current, setCurrent] = useState(1);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const {
    status: createStatus,
    error: createError,
    loading: createLoading,
  } = useDynamicSelector("create_evaluator");
  const { items: get_roles } = useDynamicSelector("roles");

  const getRoles = () => {
    let keys = [{ key: "roles" }];
    let query = get_roles_query;
    let variables = {
      page_number: current,
      page_limit: 10,
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  const handleSubmit = (values) => {
    values.role = "evaluator";
    if (initialValues?.id) {
      let variables = {
        id: initialValues?.id,
        data: values,
      };
      let keys = [{ key: "update_evaluator", loading: true }];
      dispatch(dynamicRequest(keys, "", variables, "M"));
    } else {
      let variables = {
        data: values,
      };
      let keys = [{ key: "create_evaluator", loading: true }];
      dispatch(dynamicRequest(keys, create_evaluator_mutation, variables, "M"));
    }
  };

  const columns = useBreakpointValue({
    base: 1,
    md: 2,
  });
  const handleChange = (b, a) => {};
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <Form
      form={form}
      name={"Evaluator Details"}
      layout={"vertical"}
      onFinish={handleSubmit}
      onValuesChange={handleChange}
      className="user_form ant-select-selector ant-select-selection-search"
    >
      <VForm.Grid columns={columns} spacingY={20} spacingX={50}>
        <VForm.TextBox label={t("Name")} field={"name"} />
        <VForm.TextBox label={t("Email Id")} field={"email"} />
      </VForm.Grid>
      <VForm.Grid columns={columns} spacingY={20} spacingX={50}>
        <VForm.TextBox label={t("Password")} field={"password"} />
        <VForm.Select
          label={t("Role")}
          field={"role"}
          options={get_roles}
          defaultValue="evaluator"
          disabled
          valueField={"name"}
          labelField={"name"}
        />
      </VForm.Grid>

      <HStack space="10" justifyContent={"flex-end"} marginRight="40px">
        <VForm.Button
          loading={createLoading}
          isLoading={createLoading}
          disabled={false}
        >
          {t("Submit")}
        </VForm.Button>
        <Button onClick={props.close}>{t("close")}</Button>
      </HStack>
    </Form>
  );
};
export default evaluatorDetails;
