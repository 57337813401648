import React, { useEffect, useState } from "react";
import { Box, Center, HStack, Text, useTheme, VStack } from "native-base";
import * as Icon from "react-icons/fi";
import { Spin } from "antd";
import Checkbox from "react-custom-checkbox";

const CheckBoxComponent = (props) => {
  const { loading, is_checked, onChange } = props;
  const { colors } = useTheme();
  let primary_color = colors["primary"][600];
  const onClick = (e) => {
    if (!loading && onChange) {
      onChange(!is_checked);
    }
  };

  let borderColor = loading ? "transparent" : primary_color;
  return (
    <Center w="150px" h="40px">
      <div
        style={{
          width: "20px",
          height: "20px",
          cursor: loading ? "default" : "pointer",
          backgroundColor:
            !loading && is_checked ? primary_color : "transparent",
          borderRadius: loading ? "0" : "20px",
          border: `2px solid ${borderColor}`,
        }}
        onClick={onClick}
      >
        {loading && <Spin size="small" />}
        {!loading && <Icon.FiCheck color="white" size={15} />}
      </div>
    </Center>
  );
};
export default CheckBoxComponent;
