import { Button, Form, Upload } from "antd";
import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";
import { Box, HStack, Text } from "native-base";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "formik";
import { mutation_bulk_upload_questions } from "@services/redux/slices/dynamic_entity/graphql/graphql_bulk_upload_section_questions";
import {
  dynamicClear,
  dynamicRequest,
  get_all_questions_query,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { showToast } from "@helpers/toast";

const BulkUploadModel = (props) => {
  const { sectionId, onClose } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    status: question_status,
    error: question_error,
    loading: question_Loading,
  } = useDynamicSelector("bulk_upload_questions");
  const handleUpload = (values) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(values.date[0].xhr);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, {
          header: [
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
          ],
          skipHeader: true,
          defval: "",
        });
        resolve(data);
      };
      fileReader.onerror = (error) => {
        ErrorMessage(error);
      };
    });

    promise.then((d) => {
      var removeHeader = d.slice(1);
      var itemsExcel = [];
      removeHeader.map((question, index) => {
        let dutyList = {
          question_paper_section_id: sectionId,
          ta_content: `<p><span style=\"color: rgb(0, 0, 0);\">${question.B?.toString()}&nbsp;</span></p>`,
          content: `<p><span style=\"color: rgb(0, 0, 0);\">${question.C?.toString()}&nbsp;</span></p>`,
          combined_question: question.E.length
            ? `<p>${question.E?.toString()}</p>`
            : null,
          ta_combined_question: question.D.length
            ? `<p>${question.D?.toString()}</p>`
            : null,
          ta_question_options: [
            `<p><span style=\"color: rgb(0, 0, 0);\">${question.F?.toString()}</span></p>`,
            `<p><span style=\"color: rgb(0, 0, 0);\">${question.G?.toString()}</span></p>`,
            `<p><span style=\"color: rgb(0, 0, 0);\">${question.H?.toString()}</span></p>`,
            `<p><span style=\"color: rgb(0, 0, 0);\">${question.I?.toString()}</span></p>`,
          ],
          question_options: [
            `<p><span style=\"color: rgb(0, 0, 0);\">${question.J?.toString()}</span></p>`,
            `<p><span style=\"color: rgb(0, 0, 0);\">${question.K?.toString()}</span></p>`,
            `<p><span style=\"color: rgb(0, 0, 0);\">${question.L?.toString()}</span></p>`,
            `<p><span style=\"color: rgb(0, 0, 0);\">${question.M?.toString()}</span></p>`,
          ],
          ta_answer_explanation: question.O.length
            ? `<p><span style=\"color: rgb(0, 0, 0);\">${question.O?.toString()}</span></p>`
            : null,
          answer_explanation: question.P.length
            ? `<p><span style=\"color: rgb(0, 0, 0);\">${question.P?.toString()}</span></p>`
            : null,
          correct_answer_option: parseInt(question.N),
          number: question.A?.toString(),
          sort_order: index + 1,
        };
        itemsExcel.push(dutyList);
      });
      bulk_upload_questions(itemsExcel);
    });
  };
  const getAllQuestions = () => {
    let keys = [{ key: "get_all_questions", loading: true }];
    let query = get_all_questions_query;
    let variables = {
      page_number: 1,
      page_limit: 10,
      section_id: sectionId,
    };
    dispatch(dynamicRequest(keys, query, variables));
  };
  const bulk_upload_questions = (data) => {
    let variables = {
      data: { items: data },
    };
    let keys = [{ key: "bulk_upload_questions", loading: true }];
    dispatch(
      dynamicRequest(keys, mutation_bulk_upload_questions, variables, "M")
    );
  };
  const handleSampleExport = () => {
    let inputFormat = [
      {
        "Tamil content":
          "ஒருவரின் ஊதியமானது 20% குறைக்கப்படுகிறது. மீண்டும் அவர் அதே ஊதியத்தை பெற வேண்டுமானால் எத்தனை சதவீதம் ஊதியத்தை உயர்த்த வேண்டும்? ",
        "English content":
          "The salary of a person is reduced by 20%. To restore the previous salary, his present salary is to be increased by ",
        "Tamil Combined Question": "",
        "English Combined Question": "",
        "Tamil option1": "20%",
        "Tamil option2": "0.175%",
        "Tamil option3": "0.225%",
        "Tamil option4": "0.25%",
        "English option1": "20%",
        "English option2": "0.175%",
        "English option3": "0.225%",
        "English option4": "0.25%",
        "correct option": "4",
        Number: "1",
        "Sort order": "1",
        "Answer Explanation": "",
        "Tamil Answer Explanation": "",
      },
    ];
    const worksheet = XLSX.utils.json_to_sheet(inputFormat);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Questions");
    XLSX.writeFile(workbook, "Sample Question Excel.xlsx");
  };
  useEffect(() => {
    form.resetFields();
  }, []);
  const handleClose = () => {
    onClose();
    form.resetFields();
    getAllQuestions();
  };
  useEffect(() => {
    if (question_status == "success") {
      showToast({
        type: "Success",
        message: t("question_uploaded_successfully"),
      });
      dispatch(dynamicClear("bulk_upload_questions"));
      handleClose();
    } else if (question_error == "failure") {
      showToast({
        type: "Failure",
        message: t("please_check_question"),
      });
    }
    dispatch(dynamicClear("bulk_upload_questions"));
  }, [question_error, question_status]);
  return (
    <div>
      <Form onFinish={handleUpload} layout="vertical">
        <VForm.File
          label={t("question_upload")}
          field={"date"}
          rules={[
            {
              required: true,
              message: "Please Upload a file",
            },
          ]}
        />
        <HStack space="10" justifyContent={"flex-end"} marginRight="40px">
          <Button href="https://nokkam-assets-prod.s3.ap-south-1.amazonaws.com/Question+Upload+Sample.xlsx">
            {t("sample_question_excel")}
          </Button>
          <VForm.Button loading={question_Loading}>{t("Submit")}</VForm.Button>
          <Button onClick={handleClose}>{t("close")}</Button>
        </HStack>
      </Form>
    </div>
  );
};

export default BulkUploadModel;
