import React, { useEffect } from "react";
import {
  Box,
  HStack,
  VStack,
  Text,
  Pressable,
  Stack,
  Center,
} from "native-base";
import Loader from "react-js-loader";
import EvaluatorHeaderBar from "@views/components/ui/header_bar/evaluator_header";
import { ROUTES } from "@views/routes/my_routes";
import { navigate } from "@helpers/navigator";
import {
  dynamicRequest,
  evaluator_dashboard_query,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";

const EvaluatorDashboard = () => {
  const dispatch = useDispatch();
  const { not_started, started, completed, loading } = useDynamicSelector(
    "evaluator_dashboard"
  );

  useEffect(() => {
    let keys = [{ key: "evaluator_dashboard", loading: true }];

    dispatch(dynamicRequest(keys, evaluator_dashboard_query, {}));
  }, []);
  
  const handleRoute = (route) => {
    navigate(`${ROUTES.SUBMISSION_LIST}/${route}`);
  };
  return (
    <>
      <EvaluatorHeaderBar />
      {loading ? (
        <Center mt={"45vh"}>
          <Loader
            type="spinner-default"
            bgColor={"#349481"}
            color={"#349481"}
            size={50}
          />
        </Center>
      ) : (
        <Box width="100%" alignItems="center" mt={10}>
          <Stack direction={{ base: "column", md: "row" }} space={5} pb={2}>
            <Pressable
              onPress={() => {
                handleRoute("evaluation_started");
              }}
            >
              <Box
                width="230px"
                shadow="5"
                bgImage=" linear-gradient(154deg, #ff3d00 0%, #d96743d4 85%)"
                color="white"
                borderRadius="5px"
              >
                <VStack py={2} px={4}>
                  <HStack justifyContent="space-between">
                    <Text fontSize="3xl" bold color="white"></Text>
                    <Text fontSize="md" color="white" pt={2}>
                      Partially Evaluated
                    </Text>
                  </HStack>

                  <Text fontSize="3xl" bold color="white">
                    {started || 0}
                  </Text>
                </VStack>
              </Box>
            </Pressable>
            <Pressable
              onPress={() => {
                handleRoute("evaluator_assigned");
              }}
              mt={{ base: "10", md: "" }}
            >
              <Box
                width="230px"
                shadow="5"
                bgImage=" linear-gradient(315deg, rgb(204 131 217 / 84%) 0%, rgb(149 38 203) 74%)"
                color="white"
                borderRadius="5px"
              >
                <VStack py={2} px={4}>
                  <HStack justifyContent="space-between">
                    <Text fontSize="3xl" bold color="white"></Text>
                    <Text fontSize="md" color="white" pt={2}>
                      Evaluation Not Started
                    </Text>
                  </HStack>

                  <Text fontSize="3xl" bold color="white">
                    {not_started || 0}
                  </Text>
                </VStack>
              </Box>
            </Pressable>

            <Pressable
              onPress={() => {
                handleRoute("evaluated");
              }}
              mt={{ base: "10", md: "" }}
            >
              <Box
                width="230px"
                shadow="5"
                bgImage=" linear-gradient(321deg, rgb(3 219 241 / 67%) 0%, rgb(63 81 181 / 92%) 74%)"
                color="white"
                borderRadius="5px"
              >
                <VStack py={2} px={4}>
                  <HStack justifyContent="space-between">
                    <Text fontSize="3xl" bold color="white"></Text>
                    <Text fontSize="md" color="white" pt={2}>
                      Evaluated
                    </Text>
                  </HStack>

                  <Text fontSize="3xl" bold color="white">
                    {completed || 0}
                  </Text>
                </VStack>
              </Box>
            </Pressable>
          </Stack>
        </Box>
      )}
    </>
  );
};
export default EvaluatorDashboard;
