import { gql } from "@apollo/client";

export const get_admin_dashboard_query = gql`
  query getAdminDashboard($from_date: String, $to_date: String) {
    get_admin_dashboard: getAdminDashboard(
      from_date: $from_date
      to_date: $to_date
    ) {
      items {
        id
        board_name
        object_array {
          exam_name
          question_papers {
            question_paper_title
            is_published
            publish_date
            question_paper_id
            question_paper_type
            counts {
              submitted
              total_count
              evaluation_started
              evaluated
            }
          }
        }
      }
    }
  }
`;
