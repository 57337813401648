import React, { useEffect, useState } from "react";
import VForm from "@views/components/ui/antd_form";
import { Form, Button } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { HStack } from "native-base";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
// import { useDynamicSelector } from "@services/redux";
import { get_all_board_query, get_all_exam_query } from "@services/redux";

const NotificationDetails = (props) => {
  const { t } = useTranslation();
  const { initialValues, close } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [set_value, setSelectValue] = useState();
  const [board_id, setBoardId] = useState();

  const { items: Boards } = useDynamicSelector("get_all_boards");
  const { items: Exams } = useDynamicSelector("get_all_exams");
  const get_all_boards = () => {
    let keys = [{ key: "get_all_boards", loading: true }];
    dispatch(dynamicRequest(keys, get_all_board_query, {}));
  };
  useEffect(() => {
    get_all_boards();
  }, []);

  const get_all_exams = () => {
    let keys = [{ key: "get_all_exams", loading: true }];
    let variables = { board_id: board_id };
    dispatch(dynamicRequest(keys, get_all_exam_query, variables));
  };
  useEffect(() => {
    if (board_id) get_all_exams();
  }, [board_id]);
  const type = [
    {
      label: "Note",
      value: "note",
    },
    {
      label: "Text Book",
      value: "textbook",
    },
    {
      label: "Videos",
      value: "videos",
    },
    {
      label: "Others",
      value: "others",
    },
  ];
  const handleChange = (value) => {
    if (value?.board_id) {
      setBoardId(value?.board_id);
    }
    if (value?.board_id) {
      form.setFieldsValue({ exam_id: "" });
    }
    if (value?.type === "others") {
      form.setFieldsValue({
        title: "",
        description: "",
        exam_id: "",
        board_id: "",
      });
    }
  };
  const handleChanges = (value) => {
    setSelectValue(value);
  };
  const handleClose = () => {
    close(false);
  };
  const handleSubmit = (s) => {};
  return (
    <Form
      form={form}
      name={"notification details"}
      layout={"vertical"}
      onValuesChange={handleChange}
      onFinish={handleSubmit}
      //   className="user_form ant-select-selector ant-select-selection-search"
    >
      <VForm.Select
        field={`type`}
        label={t("form:type")}
        options={type}
        fontSize={"18px"}
        labelField={"label"}
        valueField={"value"}
        onChange={handleChanges}
        rules={[
          {
            required: true,
            message: t("error:type_required"),
          },
        ]}
      />
      {set_value == "others" ? (
        <>
          <VForm.TextBox
            label={t("form:title")}
            field={"title"}
            rules={[
              {
                required: true,
                message: t("error:exam_required"),
              },
            ]}
          />
          <VForm.TextBox
            label={t("form:description")}
            field={"description"}
            rules={[
              {
                required: true,
                message: "Description is Required",
              },
            ]}
          />
        </>
      ) : (
        <>
          <VForm.Select
            field={"board_id"}
            label={t("form:board")}
            options={Boards}
            fontSize={"18px"}
            labelField={"name"}
            valueField={"id"}
            rules={[
              {
                required: true,
                message: t("error:board_required"),
              },
            ]}
          />
          <VForm.Select
            field={"exam_id"}
            label={t("form:exam")}
            options={Exams}
            fontSize={"18px"}
            labelField={"name"}
            valueField={"id"}
            rules={[
              {
                required: true,
                message: t("error:exam_required"),
              },
            ]}
          />
        </>
      )}

      <HStack space="10" justifyContent={"flex-end"} marginRight="40px">
        <VForm.Button loading={false} disabled={false}>
          {t("Submit")}
        </VForm.Button>
        <Button onClick={handleClose}>{t("close")}</Button>
      </HStack>
    </Form>
  );
};
export default NotificationDetails;
