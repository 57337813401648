import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  VStack,
  Text,
  HStack,
  Pressable,
  Center,
  IconButton,
} from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import {
  get_all_exam_query,
  useDynamicSelector,
  delete_exam_mutation,
  dynamicRequest,
  get_one_Exam_query,
  dynamicClear,
} from "@services/redux";
import ExamDetails from "./exam_details";
import AntdModal from "@views/components/ui/antd_modal";
import { Button, Pagination, Popconfirm, Table, Typography } from "antd";
import { showNotification } from "@helpers/notify";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory, useParams } from "react-router-dom";
import SimpleGrid from "@views/components/ui/simple_grid/simple_grid";
import SearchBox from "@views/components/ui/search_box/search_box";

const ExamList = (props) => {
  const dispatch = useDispatch();

  const [actionType, setActionType] = useState(null);
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { board_id, name } = useParams();
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);
  const [highlightComponent, setHighlightComponent] = useState();
  const [currentID, setCurrentId] = useState();
  const [hover, setHover] = useState(false);
  const [search, setSearch] = useState("");

  const {
    items: get_all_exams,
    loading: exam_loading,
    pagination,
  } = useDynamicSelector("get_all_exams");
  const { status: createStatus, error: createError } =
    useDynamicSelector("create_exam");
  const { status: updateStatus, error: updateError } =
    useDynamicSelector("update_exam");

  const { status: deleteStatus, error: deleteError } =
    useDynamicSelector("delete_exam");

  const handleAdd = () => {
    setActionItem({});
    setActionType("add");
    setModalOpen(true);
  };

  const handleEdit = (item) => {
    let temp = { ...item };

    if (item.syllabus)
      temp.syllabus = [
        {
          uid: "-1",
          name: "Syllabus",
          status: "done",
          url: item?.syllabus,
        },
      ];
    if (item.icon)
      temp.icon = [
        {
          uid: "-1",
          name: "Icon",
          status: "done",
          url: item?.icon,
        },
      ];
    if (item.exam_notes?.length) {
      let topics = [];
      let notes = [];
      item.exam_notes.map((x) => {
        notes.push(x.id);
        x.exam_topics?.map((y) => topics.push(y.id));
      });
      temp.notes_ids = notes;
      temp.topics = [...new Set(topics)];
    }

    setActionItem(temp);
    setActionType("edit");
    setModalOpen(true);
  };

  const handleDelete = (record) => {
    let keys = [{ key: "delete_exam", loading: true }];
    dispatch(
      dynamicRequest(keys, delete_exam_mutation, {
        id: record?.id,
      })
    );
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleResponse = (data) => {
    history.push({
      pathname: `${ROUTES.EXAM_CARDS}/${data.id}/${data.name}`,
      state: data,
    });
  };

  useEffect(() => {
    let keys = [{ key: "get_all_exams", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_exam_query, {
        page_number: current,
        page_limit: current_limit,
        search_string: search,

        board_id,
      })
    );
  }, []);

  useEffect(() => {
    if (actionType === "add" && createStatus === "success") {
      handleModalClose();
      showNotification({
        type: "success",
        message: `${"Exam"} added successfully`,
      });
      let keys = [{ key: "get_all_exams", loading: true }];

      dispatch(
        dynamicRequest(keys, get_all_exam_query, {
          page_number: current,
          page_limit: current_limit,
          board_id,
        })
      );
      dispatch(dynamicClear("create_exam"));
    } else if (actionType === "edit" && updateStatus === "success") {
      handleModalClose();

      showNotification({
        type: "success",
        message: `Exam updated successfully!`,
      });
      dispatch(dynamicClear("update_exam"));

      let keys = [{ key: "get_all_exams", loading: true }];

      dispatch(
        dynamicRequest(keys, get_all_exam_query, {
          page_number: current,
          page_limit: current_limit,
          board_id,
        })
      );
    } else if (deleteStatus === "success") {
      showNotification({
        type: "success",
        message: `${"Exam"} deleted successfully`,
      });
      dispatch(dynamicClear("delete_exam"));

      let keys = [{ key: "get_all_exams", loading: true }];

      dispatch(
        dynamicRequest(keys, get_all_exam_query, {
          page_number: current,
          page_limit: current_limit,
          board_id,
        })
      );
    }
  }, [createStatus, updateStatus, deleteStatus]);

  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "get_all_exams", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_exam_query, {
        page_number,
        page_limit,
        board_id,
      })
    );
    setCurrent(page_number);
    setCurrentLimit(page_limit);
  };

  const handleSearch = (search_txt) => {
    let keys = [{ key: "get_all_exams", loading: true }];

    dispatch(
      dynamicRequest(keys, get_all_exam_query, {
        page_limit: 10,
        page_number: 1,
        search_string: search_txt,
        board_id,
      })
    );
    setSearch(search_txt);
  };
  let columns = [
    {
      title: t("table:name"),
      dataIndex: "",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
      render: (data) => (
        <Pressable onPress={() => handleResponse(data)}>
          <Text color={"#0000ff"}>{data.name}</Text>
        </Pressable>
      ),
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      render: (record) => {
        return (
          <span>
            <Typography.Link
              onClick={() => handleEdit(record)}
              style={{ marginRight: 15 }}
            >
              <AiOutlineEdit color="blue" />
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record)}
            >
              <AiOutlineDelete color="red" />
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  return (
    <>
      <Box top="10px" zIndex="2">
        <Text fontSize="md" fontWeight="bold">
          {`${name}`} - Exams
        </Text>
      </Box>
      <Box width="100%" mt="10px" marginBottom="20px">
        <VStack space={5}>
          <HStack space={3} justifyContent="flex-end" m={3}>
            <Button type="primary" onClick={handleAdd}>
              Add Exam
            </Button>
          </HStack>
          <HStack space={3} justifyContent="flex-end">
            <SearchBox
              label={t("table:Search")}
              width={{
                base: "300px",
                xs: "200px",
                sm: "200px",
                md: "300px",
              }}
              onSearch={handleSearch}
            />
          </HStack>
          <SimpleGrid
            maxw="100%"
            w="100%"
            columns={{
              base: 1,
              xs: 1,
              md: 3,
              sm: 2,
              lg: 4,
              xl: 4,
              "2xl": 6,
            }}
            spacingY={20}
            // spacingX={5}
          >
            {get_all_exams?.map((x, index) => {
              return (
                <Pressable
                  paddingX={2}
                  key={index}
                  onPress={() => {
                    setHighlightComponent(x.id);
                    handleResponse(x);
                  }}
                  onMouseEnter={() => {
                    setHover(true);
                    setCurrentId(x.id);
                  }}
                  onMouseLeave={() => {
                    setHover(false);
                    setCurrentId(-1);
                  }}
                >
                  <Box
                    mx="5"
                    width="250px"
                    minH="150px"
                    alignSelf={"center"}
                    // bg="#160d52ba"
                    shadow="5"
                    padding="2"
                    borderRadius="20px"
                  >
                    <Box
                      style={{
                        alignItems: "end",
                        // position: "absolute",
                        // right: "0",
                      }}
                    >
                      <HStack position={"absolute"}>
                        <Box alignItems={"right"}>
                          {hover && currentID === x?.id ? (
                            <HStack
                              justifyContent={"flex-end"}
                              // style={{
                              //   position: "absolute",
                              // }}
                            >
                              <IconButton
                                variant="unstyled"
                                icon={
                                  <AiOutlineDelete size={20} color={"red"} />
                                }
                                onPress={() => {
                                  handleDelete(x);
                                }}
                              />
                              <IconButton
                                variant="unstyled"
                                icon={
                                  <AiOutlineEdit size={20} color={"blue"} />
                                }
                                onPress={() => {
                                  handleEdit(x);
                                }}
                              />
                            </HStack>
                          ) : (
                            <Text h={10}> </Text>
                          )}
                        </Box>
                      </HStack>
                    </Box>
                    <Center
                      alignItems={"center"}
                      style={{
                        justifyContent: "center",
                        flex: 1,
                      }}
                    >
                      <VStack space={10}>
                        {/* <Box
                          style={{
                            position: "absolute",
                            right: "0",
                          }}
                        >
                          <HStack position={"absolute"}>
                            <Box alignItems={"right"}>
                              {hover && currentID === x?.id ? (
                                <HStack
                                  justifyContent={"flex-end"}
                                  style={{
                                    position: "absolute",
                                  }}
                                >
                                  <IconButton
                                    variant="unstyled"
                                    icon={
                                      <AiOutlineDelete
                                        size={20}
                                        color={"red"}
                                      />
                                    }
                                    onPress={() => {
                                      handleDelete(x);
                                    }}
                                  />
                                  <IconButton
                                    variant="unstyled"
                                    icon={
                                      <AiOutlineEdit
                                        size={20}
                                        color={"primary"}
                                      />
                                    }
                                    onPress={() => {
                                      handleEdit(x);
                                    }}
                                  />
                                </HStack>
                              ) : (
                                <Text h={10}> </Text>
                              )}
                            </Box>
                          </HStack>
                        </Box> */}
                        <Box>
                          <Text
                            textAlign={"center"}
                            color={
                              highlightComponent === x.id ? "grey.700" : "black"
                            }
                            fontWeight={"600"}
                            fontSize={"18px"}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            {x.name}
                          </Text>
                        </Box>
                      </VStack>
                    </Center>
                  </Box>
                </Pressable>
              );
            })}
          </SimpleGrid>

          {get_all_exams?.length === 0 && (
            <Box alignItems={"center"}>
              <Text bold>No Exams found</Text>
            </Box>
          )}
        </VStack>
      </Box>

      <AntdModal
        isOpen={modalOpen}
        onClose={handleModalClose}
        width={650}
        header={actionType === "add" ? t("Add Exam") : t("Update Exam")}
        component={
          <ExamDetails
            label={actionType === "add" ? t("add") : t("update")}
            close={handleModalClose}
            initialValues={actionItem}
            board_id={board_id}
          />
        }
      />
    </>
  );
};
export default ExamList;
