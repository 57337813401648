import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  dynamicClear,
  dynamicRequest,
  set_ui_module_role_access_mutation,
  useDynamicSelector,
} from "@services/redux";
import CheckBoxComponent from "../checkbox/checkbox";
import PageComponentRoleCheckBox from "../page_component/page_component_role_check_box";
import { VStack, Center, Box } from "native-base";

const PageRoleCheckBox = (props) => {
  const { page, role } = props;
  const dispatch = useDispatch();

  const { id, status, loading } = useDynamicSelector(
    `setUiModuleRoleAccess-${page.id}-${role.id}`
  );
  const [has_access, setHasAccess] = useState(false);

  useEffect(() => {
    if (page) {
      let page_roles = page.roles.map((x) => x.id);
      if (page_roles.indexOf(role.id) > -1) {
        setHasAccess(true);
      } else {
        setHasAccess(false);
      }
    }
  }, [page]);

  useEffect(() => {
    if (status === "success" && id === page.id) {
      setHasAccess(!has_access);
      dispatch(dynamicClear(`setUiModuleRoleAccess-${page.id}-${role.id}`));
    }
  }, [status]);

  const onChangePageAccess = (value) => {
    if (!loading) {
      let keys = [
        {
          key: "setUiModuleRoleAccess",
          loading: true,
          variant: `${page.id}-${role.id}`,
        },
      ];
      let variables = {
        id: page.id,
        role_id: role.id,
        has_access: value,
      };
      dispatch(
        dynamicRequest(keys, set_ui_module_role_access_mutation, variables, "M")
      );
    }
  };

  return (
    <VStack>
      <CheckBoxComponent
        is_checked={has_access}
        loading={loading}
        onChange={onChangePageAccess}
      />
    </VStack>
  );
};
export default PageRoleCheckBox;
