import React, { useEffect, useState } from "react";
import { Box, Button, HStack, Text, VStack, Pressable } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AntdModal from "@views/components/ui/antd_modal";
import { showNotification } from "@helpers/notify";
import {
  delete_text_book_board_mutation,
  get_all_text_book_board_query,
  useDynamicSelector,
  dynamicRequest,
  dynamicClear,
} from "@services/redux";
import { AiOutlineEdit, AiOutlineDelete, AiFillYoutube } from "react-icons/ai";
import Wrapper from "@views/components/ui/wrapper";
import {
  Pagination,
  Popconfirm,
  Table,
  Typography,
  Space,
  Tooltip,
} from "antd";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import TextBookDetails from "./text_book_board_details";

const TextBookList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [actionItem, setActionItem] = useState({});
  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);

  const {
    items: text_board_items,
    pagination: text_board_pagination,
    loading: text_book_loading,
  } = useDynamicSelector("get_all_text_book_board");
  const { status: createStatus, error: createError } = useDynamicSelector(
    "create_text_book_board"
  );
  const { status: updateStatus, error: updateError } = useDynamicSelector(
    "update_text_book_board"
  );
  const { status: deleteStatus, error: deleteError } = useDynamicSelector(
    "delete_text_book_board"
  );

  useEffect(() => {
    let keys = [{ key: "get_all_text_book_board", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_text_book_board_query, {
        page_number: current,
        page_limit: current_limit,
      })
    );
  }, []);

  useEffect(() => {
    if (actionType === "add" && createStatus === "success") {
      handleModalClose();
      showNotification({
        type: "success",
        message: `${t("text_book_board_create_success")}`,
      });
      let keys = [{ key: "get_all_text_book_board", loading: true }];

      dispatch(dynamicRequest(keys, get_all_text_book_board_query));
      dispatch(dynamicClear("create_text_book_board"));
    } else if (actionType === "edit" && updateStatus === "success") {
      handleModalClose();

      showNotification({
        type: "success",
        message: `${t("text_book_board_update_success")}`,
      });
      dispatch(dynamicClear("update_text_book_board"));

      let keys = [{ key: "get_all_text_book_board", loading: true }];

      dispatch(dynamicRequest(keys, get_all_text_book_board_query));
    } else if (deleteStatus === "success") {
      showNotification({
        type: "success",
        message: `${t("text_book_board_delete_success")}`,
      });
      dispatch(dynamicClear("delete_text_book_board"));

      let keys = [{ key: "get_all_text_book_board", loading: true }];

      dispatch(dynamicRequest(keys, get_all_text_book_board_query));
    } else if (actionType === "add" && createStatus === "failure") {
      handleModalClose();
      showNotification({
        type: "failure",
        message: `${t("text_book_board_create_failure")}`,
      });
      let keys = [{ key: "get_all_text_book_board", loading: true }];

      dispatch(dynamicRequest(keys, get_all_text_book_board_query));
      dispatch(dynamicClear("create_text_book_board"));
    } else if (actionType === "edit" && updateStatus === "failure") {
      handleModalClose();

      showNotification({
        type: "success",
        message: `${t("text_book_board_update_failure")}`,
      });
      dispatch(dynamicClear("update_text_book_board"));

      let keys = [{ key: "get_all_text_book_board", loading: true }];

      dispatch(dynamicRequest(keys, get_all_text_book_board_query));
    } else if (deleteStatus === "failure") {
      showNotification({
        type: "success",
        message: `${t("text_book_board_delete_failure")}`,
      });
      dispatch(dynamicClear("delete_text_book_board"));

      let keys = [{ key: "get_all_text_book_board", loading: true }];

      dispatch(dynamicRequest(keys, get_all_text_book_board_query));
    }
  }, [createStatus, updateStatus, deleteStatus]);

  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleAdd = () => {
    setActionItem({});
    setActionType("add");
    setModalOpen(true);
  };

  const handleEdit = (item) => {
    let temp = { ...item };

    if (item.icon)
      temp.icon = [
        {
          uid: "-1",
          name: "Icon",
          status: "done",
          url: item?.icon,
        },
      ];
    setActionItem(temp);
    setActionType("edit");
    setModalOpen(true);
  };

  const handleDelete = (record) => {
    let keys = [{ key: "delete_text_book_board", loading: true }];
    dispatch(
      dynamicRequest(keys, delete_text_book_board_mutation, {
        id: record?.id,
      })
    );
  };

  const handleResponse = (data) => {
    history.push({
      pathname: `${ROUTES.CLASS_LIST}/${data.id}`,
      state: data,
    });
  };

  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "get_all_text_book_board", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_text_book_board_query, {
        page_number: page_number,
        page_limit: page_limit,
      })
    );
    setCurrent(page_number);
    setCurrentLimit(page_limit);
  };

  let columns = [
    {
      title: t("table:Name"),
      dataIndex: "",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
      render: (data) => (
        <Pressable onPress={() => handleResponse(data)}>
          <Text color={"#0000ff"}>{data.name}</Text>
        </Pressable>
      ),
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      render: (record) => {
        return (
          <span>
            <Typography.Link
              onClick={() => handleEdit(record)}
              style={{ marginRight: 15 }}
            >
              {/*  */}
              {/* <Text color="blue.400">Edit</Text> */}

              <Space>
                <Tooltip title="Edit" placement="bottom">
                  <AiOutlineEdit />
                </Tooltip>
              </Space>
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record)}
            >
              {/* <AiOutlineDelete /> */}

              {/* <Text color="red.400">
                  <a>Delete</a>
                </Text> */}

              <Space>
                <Tooltip title={t("delete")} placement="bottom">
                  <AiOutlineDelete />
                </Tooltip>
              </Space>
            </Popconfirm>
          </span>
        );
      },
    },
  ];
  return (
    <>
      <Box variant="text_book_board_heading_box">
        <Text variant="text_book_board_heading_text">
          {t("text_book_board")}
        </Text>
      </Box>
      <Box variant="text_book_board_top_box">
        <VStack variant="text_book_board_top_vstack">
          <HStack variant="add_text_book_board_button_hstack">
            <Box key={`header-action`}>
              <Button type="primary" onPress={handleAdd}>
                {t("add_text_book_board")}
              </Button>
            </Box>
          </HStack>
          <Wrapper>
            <Table
              loading={text_book_loading}
              columns={columns}
              dataSource={text_board_items}
              pagination={false}
            />
          </Wrapper>
          <Pagination
            total={text_board_pagination?.total_count}
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            onChange={fetchData}
            pageSizeOptions={[1, 5, 10, 20, 50]}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} text books`
            }
          />
        </VStack>
      </Box>
      <AntdModal
        isOpen={modalOpen}
        onClose={handleModalClose}
        width={450}
        header={
          actionType === "add"
            ? t("add_text_book_board")
            : t("update_text_book_board")
        }
        component={
          <TextBookDetails
            label={actionType === "add" ? t("add") : t("update")}
            close={handleModalClose}
            initialValues={actionItem}
          />
        }
      />
    </>
  );
};

export default TextBookList;
