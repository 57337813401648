import React, { useEffect } from "react";
import VForm from "@views/components/ui/antd_form";
import { Form, Button } from "antd";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  create_exam_mutation,
  update_exam_mutation,
  useDynamicSelector,
  get_all_topics_query,
  get_all_note_query,
} from "@services/redux";
import { useTranslation } from "react-i18next";
import { HStack } from "native-base";
import { useState } from "react";

const examDetails = (props) => {
  const { t } = useTranslation();
  const { initialValues, board_id } = props;
  const [exam_notes, setExamNotes] = useState([]);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { loading: createLoading } = useDynamicSelector("create_exam");
  const { loading: updateLoading } = useDynamicSelector("update_exam");
  const { items: topics } = useDynamicSelector("get_all_topics");
  const { items: notes } = useDynamicSelector("get_all_notes");
  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [initialValues]);
  useEffect(() => {
    let keys = [{ key: "get_all_topics", loading: true }];
    dispatch(dynamicRequest(keys, get_all_topics_query));
    keys = [{ key: "get_all_notes", loading: true }];
    dispatch(dynamicRequest(keys, get_all_note_query));
  }, []);
  const handleSubmit = (values) => {
    delete values.topics;

    if (initialValues?.id) {
      let variables = {
        id: initialValues?.id,
        data: {
          ...values,
          notification: values?.notification?.[0]?.url,
          syllabus: values?.syllabus?.[0]?.url,
          board_id,
        },
      };
      let keys = [{ key: "update_exam", loading: true }];
      dispatch(dynamicRequest(keys, update_exam_mutation, variables, "M"));
    } else {
      let variables = {
        data: {
          ...values,
          notification: values?.notification?.[0]?.url,
          syllabus: values?.syllabus?.[0]?.url,
          board_id,
        },
      };
      let keys = [{ key: "create_exam", loading: true }];
      dispatch(dynamicRequest(keys, create_exam_mutation, variables, "M"));
    }
  };

  const handleChange = (a, b) => {
    if (b.topics?.length) {
      setExamNotes(
        notes.filter((x) => b.topics.some((y) => y === x.exam_topics?.[0]?.id))
      );
      form.setFieldsValue({ notes_ids: [] });
    }
  };

  return (
    <Form
      form={form}
      name={"Exam Details"}
      layout={"vertical"}
      onFinish={handleSubmit}
      onValuesChange={handleChange}
      className="user_form ant-select-selector ant-select-selection-search"
    >
      <VForm.Grid columns={2} spacingY={20} spacingX={10}>
        <VForm.TextBox
          label={t("Name")}
          field={"name"}
          rules={[
            {
              required: true,
              message: "Name is Required",
            },
          ]}
        />
        <VForm.Number
          label={t("Sort Order")}
          field={"sort_order"}
          rules={[
            {
              required: true,
              message: "Sort Order is Required",
            },
          ]}
        />
        {/* <VForm.TextBox label={t("Playlist")} field={"playlist"} /> */}
      </VForm.Grid>
      {/* <VForm.Grid columns={3} spacingY={20} spacingX={10}>
        <VForm.File
          label={t("Syllabus")}
          field={"syllabus"}
          rules={[
            {
              required: true,
              message: "Syllabus is Required",
            },
          ]}
        />
        <VForm.File
          label={t("Schedule")}
          field={"schedule"}
         
        />
        <VForm.File label={t("Notification")} field={"notification"} />
      </VForm.Grid>
      <VForm.Grid columns={2} spacingY={20} spacingX={10}>
        <VForm.Select
          label={t("Topics")}
          field={"topics"}
          isMulti={true}
          options={topics}
        />
        <VForm.Select
          label={t("Notes")}
          field={"notes_ids"}
          isMulti={true}
          options={exam_notes}
        />
      </VForm.Grid> */}

      {/* <VForm.TextArea label={t("Description")} field={"description"} /> */}
      <HStack space="10" justifyContent={"flex-end"} marginRight="40px">
        <VForm.Button loading={createLoading || updateLoading} disabled={false}>
          {t("Submit")}
        </VForm.Button>
        <Button onClick={props.close}>{t("close")}</Button>
      </HStack>
    </Form>
  );
};
export default examDetails;
