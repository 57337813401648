import React from "react";
import {
  Box,
  Center,
  Hidden,
  HStack,
  Image,
  Menu,
  Pressable,
  Text,
} from "native-base";
import Avatar from "react-avatar";
import { retrieveItem } from "@helpers/storage";
import { ROUTES } from "@views/routes/my_routes";
import { navigate } from "@helpers/navigator";
import aimlogo from "@assets/icons/aim_logo.png";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
  userLogin,
} from "@services/redux";
import { MdOutlineDashboard } from "react-icons/md";
import { RiNewspaperLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { ProjectName } from "@helpers/constants";

let defaultStyles = {
  height: "70px",
};

const EvaluatorHeaderBar = (props) => {
  const dispatch = useDispatch();
  const name = retrieveItem("name");
  const handlePageChange = (page) => {
    switch (page) {
      case "dashboard":
        navigate(ROUTES.EVALUATORS_DASHBOARD);
        break;
      case "submission":
        navigate(ROUTES.SUBMISSION_LIST);
        break;
      default:
        break;
    }
  };
  const handleLogout = () => {
    dispatch(dynamicClear("admin_login"));
    localStorage.clear();
    navigate(ROUTES.LOGIN);
  };
  return (
    <>
      <HStack
        // justifyContent={"space-around"}
        py={2}
        bgImage="linear-gradient(406deg, rgb(23 153 199) 20%, #409161 80%)"
        borderBottomColor={"white"}
        borderBottomWidth={"1px"}
        // flex={1}
      >
        <Box flex={1}>
          <HStack
            pl={10}
            my="auto"
            // justifyContent={"space-around"}
          >
            <Hidden till="md">
              <Text alignSelf={"center"} bold fontSize={20} color="white">
                {ProjectName}
              </Text>
            </Hidden>
            <Hidden from="md">
              <Box width={"30%"}>
                <Text alignSelf={"center"} bold fontSize={20} color="white">
                  {ProjectName}
                </Text>
              </Box>
            </Hidden>
          </HStack>
        </Box>
        <Box flex={1}>
          <Center>
            <Image
              h="10"
              size="20"
              alt="logo"
              resizeMode={"contain"}
              source={aimlogo}
            />
          </Center>
        </Box>
        <Box flex={1} my="auto">
          <HStack justifyContent={"flex-end"}>
            <Box right="10px" ml="6">
              <Menu
                w="160"
                position="absolute"
                right="0px"
                top="20px"
                style={{
                  border: "1px solid #d7d7d7",
                  boxShadow: "-2px 2px #7a7a7a42",
                }}
                shouldOverlapWithTrigger={true}
                placement={"left bottom"}
                trigger={(triggerProps) => {
                  return (
                    <Pressable
                      alignSelf="center"
                      alignItems="center"
                      variant="solid"
                      {...triggerProps}
                    >
                      <HStack>
                        <Hidden till="md">
                          <Text
                            bold
                            fontSize="md"
                            fontWeight=""
                            color="#ffffff"
                          >
                            {name}
                          </Text>
                        </Hidden>
                        <Box right="10px" ml="6">
                          <Avatar
                            round
                            size="25"
                            src={
                              "https://aim-assets.s3.ap-south-1.amazonaws.com/default-avatar.png"
                            }
                          />
                        </Box>
                      </HStack>
                    </Pressable>
                  );
                }}
              >
                <Hidden from="md">
                  <Menu.Item>
                    <Text bold fontSize="md">
                      {name}
                    </Text>
                  </Menu.Item>
                </Hidden>
                <Menu.Item onPress={handleLogout}>Logout</Menu.Item>
              </Menu>
            </Box>
          </HStack>
        </Box>
      </HStack>
      <HStack
        py={2}
        bgImage="linear-gradient(406deg, rgb(23 153 199) 20%, #409161 80%)"
        space={10}
        pl={5}
      >
        <Pressable
          alignSelf="center"
          alignItems="center"
          variant="solid"
          onPress={() => handlePageChange("dashboard")}
        >
          <HStack space={1}>
            <Box alignSelf={"center"} bold color="white">
              <MdOutlineDashboard color="white" />
            </Box>
            <Text alignSelf={"center"} bold color="white">
              Dashboard
            </Text>
          </HStack>
        </Pressable>
        <Pressable
          alignSelf="center"
          alignItems="center"
          variant="solid"
          onPress={() => handlePageChange("submission")}
        >
          <HStack space={1}>
            <Box alignSelf={"center"} bold color="white">
              <RiNewspaperLine color="white" />
            </Box>
            <Text alignSelf={"center"} bold color="white">
              Submissions
            </Text>
          </HStack>
        </Pressable>
      </HStack>
    </>
  );
};

export default EvaluatorHeaderBar;
