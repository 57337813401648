import React, { useEffect, useState } from "react";
import { Center, HStack, Text } from "native-base";
import { Button } from "antd";

const Confirmation = (props) => {
  const { submission, submission_result, endEvaluation } = props;

  return (
    <>
      <HStack mb={6}>
        <Text bold w={"300px"} alignSelf="center">
          General Comment:
        </Text>
        <Text>{submission_result?.user_paper_evaluations?.[0]?.comment}</Text>
      </HStack>
      <table>
        <thead>
          <tr>
            <th>Question</th>
            <th>Mark</th>
            <th style={{ width: "30%" }}>Comment</th>
          </tr>
        </thead>
        <tbody>
          {submission?.question_paper?.question_paper_sections?.map(
            (section) => (
              <>
                <tr>
                  <td
                    colSpan={2}
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    {section.name}
                  </td>
                  <td>
                    {
                      submission_result?.user_section_evaluations?.find(
                        (x) => x.question_paper_section_id === section.id
                      )?.comment
                    }
                  </td>
                </tr>
                {section.section_questions.map((question) => (
                  <tr>
                    <td>
                      <Text>
                        {question.number}). {question.content}
                      </Text>
                    </td>
                    <td>
                      <Text>
                        {submission_result?.user_question_evaluations?.find(
                          (x) => x.question_id === question.id
                        )?.mark || 0}
                      </Text>
                    </td>
                    <td>
                      <Text>
                        {
                          submission_result?.user_question_evaluations?.find(
                            (x) => x.question_id === question.id
                          )?.comment
                        }
                      </Text>
                    </td>
                  </tr>
                ))}
              </>
            )
          )}
        </tbody>
      </table>
      <Center my={5}>
        <Button type="primary" onClick={endEvaluation}>
          Submit
        </Button>
      </Center>
    </>
  );
};
export default Confirmation;
