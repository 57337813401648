import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, Text, HStack, Pressable } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import {
  get_all_exam_query,
  useDynamicSelector,
  delete_exam_mutation,
  dynamicRequest,
  get_one_Exam_query,
  dynamicClear,
  update_exam_mutation,
} from "@services/redux";
import ExamDetails from "./exam_details";
import AntdModal from "@views/components/ui/antd_modal";
import {
  Button,
  Form,
  Modal,
  Pagination,
  Popconfirm,
  Table,
  Typography,
} from "antd";
import { showNotification } from "@helpers/notify";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory, useParams } from "react-router-dom";
import SimpleGrid from "@views/components/ui/simple_grid/simple_grid";
import { FaDownload } from "react-icons/fa";
import { IoIosArrowForward, IoIosPaper } from "react-icons/io";
import VForm from "@views/components/ui/antd_form";
import ExamNotesDetails from "./exam_notes";
import { GrNotes } from "react-icons/gr";
import { BiVideo } from "react-icons/bi";

const ExamCards = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [actionType, setActionType] = useState(null);
  const [actionItem, setActionItem] = useState("");
  const { t } = useTranslation();
  const { exam_id, exam_name } = useParams();
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [rerender, setRerender] = useState(false);

  const [modalExamNotesOpen, setModalExamNotesOpen] = useState(false);

  const {
    name,
    class_schedule,
    test_schedule,
    syllabus,
    notification,
    exam_notes,
    notification_view_count,
    notification_download_count,
    syllabus_view_count,
    syllabus_download_count,
    class_schedule_view_count,
    class_schedule_download_count,
    test_schedule_view_count,
    test_schedule_download_count,
  } = useDynamicSelector("get_exam");

  const { status: updateStatus, error: updateError } =
    useDynamicSelector("update_exam");

  const handleAdd = (val) => {
    setActionItem("");
    setActionType(val);
    setModalOpen(true);
  };
  const handleExamNotesAdd = (val) => {
    setRerender(true);
    let temp = {};

    if (exam_notes?.length) {
      let topics = [];
      let notes = [];
      exam_notes.map((x) => {
        notes.push(x.id);
        x.exam_topics?.map((y) => topics.push(y.id));
      });
      temp.notes_ids = notes;
      temp.topics = [...new Set(topics)];
    }

    setActionItem(temp);
    setActionType(val);
    setModalExamNotesOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    history.go(0);
  };
  const handleExamNotesModalClose = () => {
    setModalExamNotesOpen(false);
  };
  const handleResponse = (data) => {
    history.push({
      pathname: `${ROUTES.QUESTIONS_LIST}/${exam_id}/${exam_name}`,
      state: exam_name,
    });
  };

  const handlePreviousYearPaper = (data) => {
    history.push({
      pathname: `${ROUTES.PREVIOUS_YEAR_PAPER}/${exam_id}/${exam_name}`,
      state: exam_name,
    });
  };

  const handlePlayListVideo = (data) => {
    history.push({
      pathname: `${ROUTES.EXAM_VIDEO_PLAY_LIST}/${exam_id}/${exam_name}`,
      state: exam_name,
    });
  };
  const handleSubmit = (values) => {
    delete values.topics;
    values.name = name;
    values.test_schedule = test_schedule
      ? test_schedule
      : values?.test_schedule?.[0]?.url;
    values.notification = notification
      ? notification
      : values?.notification?.[0]?.url;
    values.class_schedule = class_schedule
      ? class_schedule
      : values?.class_schedule?.[0]?.url;
    values.syllabus = syllabus ? syllabus : values?.syllabus?.[0]?.url;

    if (exam_id) {
      let variables = {
        id: exam_id,
        data: {
          ...values,

          //   board_id,
        },
      };
      let keys = [{ key: "update_exam", loading: true }];
      dispatch(dynamicRequest(keys, update_exam_mutation, variables, "M"));
    }
  };

  useEffect(() => {
    if (updateError) {
      handleModalClose();
      showNotification({
        type: "error",
        message: `Something went wrong!`,
      });

      dispatch(dynamicClear("update_exam"));
    } else if (updateStatus === "success") {
      handleModalClose();
      handleExamNotesModalClose();
      showNotification({
        type: "success",
        message: `Exam updated successfully!`,
      });
      dispatch(dynamicClear("update_exam"));
      handleExamNotesModalClose();

      let keys = [{ key: "get_exam", loading: true }];
      dispatch(
        dynamicRequest(keys, get_one_Exam_query, {
          id: exam_id,
        })
      );
    }
  }, [updateStatus, updateError]);

  useEffect(() => {
    if (actionType === "class_schedule") {
      setActionItem(class_schedule);
    } else if (actionType === "test_schedule") {
      setActionItem(test_schedule);
    } else if (actionType === "syllabus") {
      setActionItem(syllabus);
    } else if (actionType === "notification") {
      setActionItem(notification);
    }
  }, [actionType]);
  useEffect(() => {
    form.resetFields();
    if (actionItem) {
      form.setFieldsValue({
        class_schedule: [
          {
            uid: "-1",
            name: actionItem ? "Document" : "",
            status: "done",
            url: actionItem?.toString(),
          },
        ],
        test_schedule: [
          {
            uid: "-1",
            name: actionItem ? "Document" : "",
            status: "done",
            url: actionItem?.toString(),
          },
        ],
        syllabus: [
          {
            uid: "-1",
            name: actionItem ? "Document" : "",
            status: "done",
            url: actionItem?.toString(),
          },
        ],
        notification: [
          {
            uid: "-1",
            name: actionItem ? "Document" : "",
            status: "done",
            url: actionItem?.toString(),
          },
        ],
      });
    }
  }, [actionItem]);
  useEffect(() => {
    let keys = [{ key: "get_exam", loading: true }];
    dispatch(
      dynamicRequest(keys, get_one_Exam_query, {
        id: exam_id,
      })
    );
  }, []);

  return (
    <>
      <Box top="10px" zIndex="2">
        <Text fontSize="md" fontWeight="bold">
          {`${exam_name}`}
        </Text>
      </Box>
      <Box width="100%" mt="10px" marginBottom="20px" alignItems={"center"}>
        <SimpleGrid
          maxw="100%"
          w="100%"
          columns={{
            base: 1,
            xs: 1,
            md: 2,
            sm: 2,
            lg: 2,
            xl: 2,
            "2xl": 6,
          }}
          spacingY={20}
          spacingX={21}
        >
          <Box
            mx="5"
            width="400px"
            h="70px"
            alignSelf={"center"}
            bg="#8080801f"
            padding="2"
            borderRadius="5px"
            onClick={() => {
              handleResponse();
            }}
          >
            <Box
              padding={5}
              style={{
                justifyContent: "center",
                flex: 1,
              }}
            >
              <HStack space={10}>
                <Box width="75%">
                  <Text
                    textALign={"left"}
                    fontWeight={"600"}
                    fontSize={"18px"}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Test
                  </Text>
                </Box>
                <Box flex={1} alignSelf="center">
                  <IoIosArrowForward size="25" />
                </Box>
              </HStack>
            </Box>
          </Box>
          <Box
            mx="5"
            width="400px"
            h="70px"
            alignSelf={"center"}
            bg="#8080801f"
            padding="2"
            borderRadius="5px"
            onClick={() => {
              handlePlayListVideo();
            }}
          >
            <Box
              padding={5}
              style={{
                justifyContent: "center",
                flex: 1,
              }}
            >
              <HStack space={10}>
                <Box width="75%">
                  <Text
                    textALign={"left"}
                    fontWeight={"600"}
                    fontSize={"18px"}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Videos
                  </Text>
                </Box>
                <Box flex={1} alignSelf="center">
                  <BiVideo size="25" />
                </Box>
              </HStack>
            </Box>
          </Box>
          <Box
            mx="5"
            width="400px"
            h="70px"
            alignSelf={"center"}
            bg="#8080801f"
            padding="2"
            borderRadius="5px"
            onClick={() => {
              handlePreviousYearPaper();
            }}
          >
            <Box
              padding={5}
              style={{
                justifyContent: "center",
                flex: 1,
              }}
            >
              <HStack space={10}>
                <Box width="75%">
                  <Text
                    textALign={"left"}
                    fontWeight={"600"}
                    fontSize={"18px"}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Previous Year Paper
                  </Text>
                </Box>
                <Box flex={1} alignSelf="center">
                  <IoIosPaper size="25" />
                </Box>
              </HStack>
            </Box>
          </Box>
          <Box
            mx="5"
            width="400px"
            h="70px"
            alignSelf={"center"}
            bg="#8080801f"
            padding="2"
            borderRadius="5px"
            onClick={() => {
              handleExamNotesAdd();
            }}
          >
            <Box
              padding={5}
              style={{
                justifyContent: "center",
                flex: 1,
              }}
            >
              <HStack space={10}>
                <Box width="75%">
                  <Text
                    textALign={"left"}
                    fontWeight={"600"}
                    fontSize={"18px"}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Notes
                  </Text>
                </Box>
                <Box flex={1} alignSelf="center">
                  <GrNotes size="25" />
                </Box>
              </HStack>
            </Box>
          </Box>
          <Box
            mx="5"
            width="400px"
            h="120px"
            alignSelf={"center"}
            bg="#8080801f"
            padding="2"
            borderRadius="5px"
            onClick={() => {
              handleAdd("notification");
            }}
          >
            <Box
              padding={5}
              style={{
                justifyContent: "center",
                flex: 1,
              }}
            >
              <VStack space="3">
                <HStack space={10}>
                  <Box width="75%">
                    <Text
                      textALign={"left"}
                      fontWeight={"600"}
                      fontSize={"18px"}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Notification
                    </Text>
                  </Box>
                  <Box flex={1} alignSelf="center">
                    {notification ? <FaDownload size="25" /> : ""}
                  </Box>
                </HStack>
                <HStack space={10}>
                  <Box width="75%">
                    <Text
                      textALign={"left"}
                      fontWeight={"600"}
                      fontSize="14px"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Number of Views
                    </Text>
                  </Box>
                  <Box flex={1} alignSelf="right">
                    <Text textAlign={"right"}>{notification_view_count}</Text>
                  </Box>
                </HStack>
                <HStack space={10}>
                  <Box width="75%">
                    <Text
                      textALign={"left"}
                      fontWeight={"600"}
                      fontSize="14px"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Number of downloads
                    </Text>
                  </Box>
                  <Box flex={1} alignSelf="right">
                    <Text textAlign={"right"}>
                      {notification_download_count}
                    </Text>
                  </Box>
                </HStack>
              </VStack>
            </Box>
          </Box>
          <Box
            mx="5"
            width="400px"
            h="120px"
            alignSelf={"center"}
            bg="#8080801f"
            padding="2"
            borderRadius="5px"
            onClick={() => {
              handleAdd("test_schedule");
            }}
          >
            <Box
              padding={5}
              style={{
                justifyContent: "center",
                flex: 1,
              }}
            >
              <VStack space={"3"}>
                <HStack space={10}>
                  <Box width="75%">
                    <Text
                      textALign={"left"}
                      fontWeight={"600"}
                      fontSize={"18px"}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Test Schedule
                    </Text>
                  </Box>
                  <Box flex={1} alignSelf="center">
                    {test_schedule ? <FaDownload size="25" /> : ""}
                  </Box>
                </HStack>
                <HStack space={10}>
                  <Box width="75%">
                    <Text
                      textALign={"left"}
                      fontWeight={"600"}
                      fontSize="14px"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Number of Views
                    </Text>
                  </Box>
                  <Box flex={1} alignSelf="right">
                    <Text textAlign={"right"}>{test_schedule_view_count}</Text>
                  </Box>
                </HStack>
                <HStack space={10}>
                  <Box width="75%">
                    <Text
                      textALign={"left"}
                      fontWeight={"600"}
                      fontSize="14px"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Number of downloads
                    </Text>
                  </Box>
                  <Box flex={1} alignSelf="right">
                    <Text textAlign={"right"}>
                      {test_schedule_download_count}
                    </Text>
                  </Box>
                </HStack>
              </VStack>
            </Box>
          </Box>
          <Box
            mx="5"
            width="400px"
            h="120px"
            alignSelf={"center"}
            bg="#8080801f"
            padding="2"
            borderRadius="5px"
            onClick={() => {
              handleAdd("class_schedule");
            }}
          >
            <Box
              padding={5}
              style={{
                justifyContent: "center",
                flex: 1,
              }}
            >
              <VStack space="3">
                <HStack space={10}>
                  <Box width="75%">
                    <Text
                      textALign={"left"}
                      fontWeight={"600"}
                      fontSize={"18px"}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Class Schedule
                    </Text>
                  </Box>
                  <Box flex={1} alignSelf="center">
                    {class_schedule ? <FaDownload size="25" /> : ""}
                  </Box>
                </HStack>
                <HStack space={10}>
                  <Box width="75%">
                    <Text
                      textALign={"left"}
                      fontWeight={"600"}
                      fontSize="14px"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Number of Views
                    </Text>
                  </Box>
                  <Box flex={1} alignSelf="right">
                    <Text textAlign={"right"}>{class_schedule_view_count}</Text>
                  </Box>
                </HStack>
                <HStack space={10}>
                  <Box width="75%">
                    <Text
                      textALign={"left"}
                      fontWeight={"600"}
                      fontSize="14px"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Number of downloads
                    </Text>
                  </Box>
                  <Box flex={1} alignSelf="right">
                    <Text textAlign={"right"}>
                      {class_schedule_download_count}
                    </Text>
                  </Box>
                </HStack>
              </VStack>
            </Box>
          </Box>
          <Box
            mx="5"
            width="400px"
            h="120px"
            alignSelf={"center"}
            bg="#8080801f"
            padding="2"
            borderRadius="5px"
            onClick={() => {
              handleAdd("syllabus");
            }}
          >
            <Box
              padding={5}
              style={{
                justifyContent: "center",
                flex: 1,
              }}
            >
              <VStack space="3">
                <HStack space={10}>
                  <Box width="75%">
                    <Text
                      textALign={"left"}
                      fontWeight={"600"}
                      fontSize={"18px"}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Syllabus
                    </Text>
                  </Box>
                  <Box flex={1} alignSelf="center">
                    {syllabus ? (
                      <a href={syllabus} target={"_blank"}>
                        <FaDownload size="25" color="black" />
                      </a>
                    ) : (
                      ""
                    )}
                  </Box>
                </HStack>
                <HStack space={10}>
                  <Box width="75%">
                    <Text
                      textALign={"left"}
                      fontWeight={"600"}
                      fontSize="14px"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Number of Views
                    </Text>
                  </Box>
                  <Box flex={1} alignSelf="right">
                    <Text textAlign={"right"}>{syllabus_view_count}</Text>
                  </Box>
                </HStack>
                <HStack space={10}>
                  <Box width="75%">
                    <Text
                      textALign={"left"}
                      fontWeight={"600"}
                      fontSize="14px"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Number of downloads
                    </Text>
                  </Box>

                  <Box flex={1} alignSelf="right">
                    <Text textAlign={"right"}>{syllabus_download_count}</Text>
                  </Box>
                </HStack>
              </VStack>
            </Box>
          </Box>
        </SimpleGrid>
      </Box>
      <HStack justifyContent={"flex-end"}>
        <Button
          type="default"
          danger
          onClick={() => {
            history.goBack();
          }}
        >
          Back
        </Button>
      </HStack>
      <AntdModal
        isOpen={modalExamNotesOpen}
        onClose={handleExamNotesModalClose}
        width={"1000px"}
        header={actionType === "add" ? t("Add Exam") : t("Update Exam")}
        component={
          <ExamNotesDetails
            rerender={rerender}
            label={actionType === "add" ? t("add") : t("update")}
            close={handleExamNotesModalClose}
            initialValues={actionItem}
            exam_id={exam_id}
            exam_name={exam_name}
            exam_note={exam_notes}
          />
        }
      />
      <Modal
        open={modalOpen}
        onCancel={handleModalClose}
        title={
          actionType === "class_schedule"
            ? t("Class Schedule")
            : actionType === "test_schedule"
            ? "Test Schedule"
            : actionType === "syllabus"
            ? "Syllabus"
            : "Notification"
        }
        width={500}
        footer={null}
      >
        <Form
          form={form}
          //   initialValues={actionItem}
          name={"Exam Details"}
          layout={"vertical"}
          onFinish={handleSubmit}
        >
          <VForm.File
            label={t("File")}
            field={actionType}
            rules={[
              {
                required: true,
                message: "File is Required",
              },
            ]}
          />
          <HStack space="10" justifyContent={"flex-end"} marginRight="40px">
            <VForm.Button
              //   loading={updateLoading}
              disabled={false}
            >
              {t("Submit")}
            </VForm.Button>
            <Button
              onClick={() => {
                handleModalClose();
              }}
            >
              {t("close")}
            </Button>
          </HStack>
        </Form>
      </Modal>
    </>
  );
};
export default ExamCards;
