import React, { useEffect } from "react";
import VForm from "@views/components/ui/antd_form";
import { Form, Button } from "antd";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  create_previous_year_question_paper_mutation,
  update_previous_year_question_paper_mutation,
  useDynamicSelector,
  get_all_previous_year_question_paper_query,
  dynamicClear,
} from "@services/redux";
import { useTranslation } from "react-i18next";
import { HStack } from "native-base";
import { showNotification } from "@helpers/notify";

const PreviousYearQuestionPaperDetails = (props) => {
  const { t } = useTranslation();
  const { exam_id, actionType, initialValues, close } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    loading: createLoading,
    status: create_status,
    error: create_error,
  } = useDynamicSelector("create_previous_year_question_paper");
  const {
    loading: updateLoading,
    status: update_status,
    error: update_error,
  } = useDynamicSelector("update_previous_year_question_paper");

  useEffect(() => {
    if (create_status === "success") {
      close();
      showNotification({
        type: "success",
        message: `${"Question Paper"} added successfully`,
      });
      let keys = [
        { key: "get_all_previous_year_question_papers", loading: true },
      ];

      dispatch(
        dynamicRequest(keys, get_all_previous_year_question_paper_query, {
          exam_id,
        })
      );
      dispatch(dynamicClear("create_previous_year_question_paper"));
    } else if (create_error) {
      close();
      showNotification({
        type: "error",
        message: `Something went wrong!`,
      });
      let keys = [
        { key: "get_all_previous_year_question_papers", loading: true },
      ];

      dispatch(
        dynamicRequest(keys, get_all_previous_year_question_paper_query, {
          exam_id,
        })
      );
      dispatch(dynamicClear("create_previous_year_question_paper"));
    } else if (update_status === "success") {
      close();

      showNotification({
        type: "success",
        message: `${"Question Paper"} updated successfully`,
      });
      dispatch(dynamicClear("update_previous_year_question_paper"));

      let keys = [
        { key: "get_all_previous_year_question_papers", loading: true },
      ];

      dispatch(
        dynamicRequest(keys, get_all_previous_year_question_paper_query, {
          exam_id,
        })
      );
    } else if (update_error) {
      close();
      showNotification({
        type: "error",
        message: `Something went wrong!`,
      });
      let keys = [
        { key: "get_all_previous_year_question_papers", loading: true },
      ];

      dispatch(
        dynamicRequest(keys, get_all_previous_year_question_paper_query, {
          exam_id,
        })
      );
      dispatch(dynamicClear("update_previous_year_question_paper"));
    }
  }, [create_status, update_status, create_error, update_error]);

  useEffect(() => {
    form.resetFields();
    if (initialValues?.id)
      form.setFieldsValue({
        ...initialValues,
        question_paper_url: [
          {
            uid: "-1",
            name: initialValues?.year
              ? initialValues?.year + "Question Paper"
              : "",
            status: "done",
            url: initialValues?.question_paper_url?.toString(),
          },
        ],
      });
  }, [initialValues]);

  const handleSubmit = (values) => {
    values.year = actionType;
    if (initialValues?.id) {
      let variables = {
        id: initialValues?.id,
        data: {
          ...values,
          question_paper_url: values?.question_paper_url?.[0]?.url,
          exam_id,
        },
      };
      let keys = [
        { key: "update_previous_year_question_paper", loading: true },
      ];
      dispatch(
        dynamicRequest(
          keys,
          update_previous_year_question_paper_mutation,
          variables,
          "M"
        )
      );
    } else {
      let variables = {
        data: {
          ...values,
          question_paper_url: values?.question_paper_url?.[0]?.url,
          exam_id,
        },
      };
      let keys = [
        { key: "create_previous_year_question_paper", loading: true },
      ];
      dispatch(
        dynamicRequest(
          keys,
          create_previous_year_question_paper_mutation,
          variables,
          "M"
        )
      );
    }
  };

  const handleChange = (a, b) => {};

  return (
    <Form
      form={form}
      name={"Exam Details"}
      layout={"vertical"}
      onFinish={handleSubmit}
      onValuesChange={handleChange}
      className="user_form ant-select-selector ant-select-selection-search"
    >
      <VForm.File label={t("Question Paper")} field={"question_paper_url"} />

      <HStack space="10" justifyContent={"flex-end"} marginRight="40px">
        <VForm.Button
          loading={initialValues?.id ? updateLoading : createLoading}
          disabled={false}
        >
          {t("Submit")}
        </VForm.Button>
        <Button onClick={props.close}>{t("close")}</Button>
      </HStack>
    </Form>
  );
};
export default PreviousYearQuestionPaperDetails;
