import React, { useEffect, useState } from "react";
import { Box, Button, HStack, Text, VStack, Pressable } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { showToast } from "@helpers/toast";
import AntdModal from "@views/components/ui/antd_modal";
import ActionButton from "@views/components/ui/dialog/action_button";
import { showNotification } from "@helpers/notify";

import { AiOutlineEdit, AiOutlineDelete, AiFillYoutube } from "react-icons/ai";
import Wrapper from "@views/components/ui/wrapper";
import { Pagination, Popconfirm, Table, Typography } from "antd";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import NotificationDetails from "./notification_details";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { get_all_notifications } from "@services/redux/slices/dynamic_entity/graphql/graphql_notification";
import moment from "moment";

const NotificationList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [actionItem, setActionItem] = useState({});
  const [dialogVisible, setDialogVisible] = useState(false);
  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [actionType, setActionType] = useState(null);

  const {
    items: notifications,
    pagination,
    loading,
  } = useDynamicSelector("getNotifications");

  const handleAdd = () => {
    setActionItem({});
    setActionType("add");
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleGetNotifications = () => {
    let keys = [{ key: "getNotifications", loading: true }];
    let query = get_all_notifications;
    let variables = {
      json: { page_number: current, page_limit: current_limit },
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "getNotifications", loading: true }];
    let query = get_all_notifications;
    let variables = { json: { page_number, page_limit } };
    dispatch(dynamicRequest(keys, query, variables));
    setCurrent(page_number);
    setCurrentLimit(page_limit);
  };
  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    return moment(parseInt(date)).format("DD/MM/YYYY");
  };

  const formatType = (type) => {
    switch (type) {
      case "question_paper":
        return "Question Paper";
      default:
        return type;
    }
  };
  useEffect(() => {
    handleGetNotifications();
  }, []);
  let columns = [
    {
      title: t("table:type"),
      dataIndex: "type",
      key: "type",
      sortable: false,
      align: "left",
      flex: 1,
      render: (data) => {
        return formatType(data);
      },
    },
    {
      title: t("table:created_on"),
      dataIndex: "datetime",
      key: "created_on",
      sortable: false,
      align: "left",
      flex: 1,
      render: (datetime) => {
        return formatDate(datetime);
      },
    },

    {
      title: t("table:name"),
      dataIndex: "",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:title"),
      dataIndex: "title",
      key: "title",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:description"),
      dataIndex: "description",
      key: "description",
      sortable: false,
      align: "left",
      flex: 1,
    },
  ];

  return (
    <>
      <Box position="sticky" top="10px" zIndex="2" bg="white">
        <Text fontSize="md" fontWeight="bold">
          {t("notification_list")}
        </Text>
      </Box>
      <Box width="100%" mt="10px" marginBottom="20px">
        <VStack space={5}>
          <HStack space={3} justifyContent="flex-end" m={3}>
            <Box key={`header-action`}>
              <Button type="primary" onPress={handleAdd}>
                {t("add_notification")}
              </Button>
            </Box>
          </HStack>
          <Wrapper>
            <Table
              loading={loading}
              columns={columns}
              dataSource={notifications}
              pagination={false}
            />
          </Wrapper>
          <Pagination
            total={pagination?.total_count}
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            onChange={fetchData}
          />
        </VStack>
      </Box>
      <AntdModal
        isOpen={modalOpen}
        onClose={handleModalClose}
        width={450}
        header={t("Add Notification")}
        component={
          <NotificationDetails
            label={"add"}
            close={handleModalClose}
            initialValues={actionItem}
          />
        }
      />
    </>
  );
};
export default NotificationList;
