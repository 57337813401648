import { gql } from "@apollo/client";

export const create_note_mutation = gql`
  mutation createExamNote($data: exam_note_input) {
    create_note: createExamNote(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_note_mutation = gql`
  mutation updateExamNote($data: exam_note_input, $id: String!) {
    update_note: updateExamNote(data: $data, id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_note_mutation = gql`
  mutation deleteExamNote($id: String!) {
    delete_note: deleteExamNote(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_note_query = gql`
  query gelAllExamNote(
    $page_number: Float
    $page_limit: Float
    $topic_id: String
  ) {
    get_all_notes: getAllExamNotes(
      page_number: $page_number
      page_limit: $page_limit
      topic_id: $topic_id
    ) {
      items {
        id
        name
        note_view_count
        note_download_count
        exam_topics {
          id
          name
        }
        url
        sort_order
        description
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;
export const get_all_note_for_exam_notes_query = gql`
  query getAllExamOrderedNotes($topic_id: String, $exam_id: String) {
    get_all_ordered_notes: getAllExamOrderedNotes(
      topic_id: $topic_id
      exam_id: $exam_id
    ) {
      items {
        note_id
        sort_order

        exam_note {
          id
          exam_topics {
            id
            name
          }
          sort_order
          name
        }
      }
    }
  }
`;

export const get_one_note_query = gql`
  query {
    get_note: getExamNote {
      id
      name
      error {
        status_code
        message
      }
    }
  }
`;
