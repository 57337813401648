import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, HStack, VStack, Center, Text, Pressable } from "native-base";
// import { dashboardSelector, dashboard } from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { isPending } from "@reduxjs/toolkit";
import Loader from "react-js-loader";
import {
  dynamicRequest,
  useDynamicSelector,
  admin_dash_board_details_query,
  get_admin_dashboard_query,
} from "@services/redux";
import { ProjectName } from "@helpers/constants";
import { DatePicker, Table, Tag } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";

const Dashboard = () => {
  const { t } = useTranslation();
  var size = 0;
  const dispatch = useDispatch();
  const history = useHistory();
  const [current, setCurrent] = useState(1);
  const [exam_list, setExamList] = useState([]);
  const [question_papers, setQuestionPapers] = useState([]);
  const [show_exam_list, setShowExamList] = useState(false);
  const [show_question_papers, setShowQuestionPapers] = useState(false);
  const [from_date, setFromDate] = useState();
  const [to_date, setToDate] = useState();

  const {
    evaluated_paper_count,
    submitted_count,
    evaluator_assigned_paper_count,
    question_paper_published_count,
    question_paper_un_published_count,
    loading,
  } = useDynamicSelector("admin_dashboard_details");

  const { items } = useDynamicSelector("get_admin_dashboard");
  useEffect(() => {
    let keys = [{ key: "admin_dashboard_details", loading: true }];
    dispatch(dynamicRequest(keys, admin_dash_board_details_query, {}));
  }, []);

  useEffect(() => {
    const inputFromDate = moment(from_date, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
    const inputToDate = moment(to_date, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
    let keys = [{ key: "get_admin_dashboard", loading: true }];
    let variables = {
      from_date: from_date ? inputFromDate?.format("DD-MM-YYYY") : "",
      to_date: to_date ? inputToDate?.format("DD-MM-YYYY") : "",
    };
    dispatch(dynamicRequest(keys, get_admin_dashboard_query, variables));
  }, [from_date, to_date]);

  if (items) {
    size = Object.keys(items).length;
  }

  let bg_arr = [
    "linear-gradient(154deg, #ff3d00 0%, #d96743d4 85%)",
    " linear-gradient(315deg, rgb(204 131 217 / 84%) 0%, rgb(149 38 203) 74%)",
    " linear-gradient(321deg, rgb(3 219 241 / 67%) 0%, rgb(63 81 181 / 92%) 74%)",
    " linear-gradient(315deg, rgb(103 58 183 / 52%) 0%, rgb(37 51 221 / 76%) 74%)",
    "linear-gradient(315deg, rgb(103 58 183 / 52%) 0%, rgb(37 51 221 / 76%) 74%)",
  ];

  const examDetails = (obj_array) => {
    setExamList(obj_array);
    setShowExamList(true);
    setShowQuestionPapers(false);
  };

  const questionPaperDetails = (question_papers) => {
    console.log("QuestionPaper", question_papers);
    setQuestionPapers(question_papers);
    setShowQuestionPapers(true);
  };

  let columns = [
    {
      title: t("table:question_paper_title"),
      dataIndex: "question_paper_title",
      key: "question_paper_title",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:question_paper_type"),
      dataIndex: "question_paper_type",
      key: "question_paper_type",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        if (record === "objective") return "Objective";
        else if (record === "descriptive") return "Descriptive";
        else return "Objective & Descriptive";
      },
    },
    {
      title: t("table:total_count"),
      dataIndex: "counts.total_count",
      key: "total_count",
      sortable: false,
      align: "left",
      flex: 1,
      render: (text, record) => (
        <>
          <div>{record.counts.total_count}</div>
        </>
      ),
    },
    {
      title: t("table:is_published"),
      dataIndex: "is_published",
      key: "is_published",
      sortable: false,
      align: "left",
      flex: 1,
      render: (text, record) => (
        <>
          <Tag color={record ? "green" : "volcano"}>
            {record ? "Published" : "Not Published"}
          </Tag>
        </>
      ),
    },
    {
      title: t("table:publish_date"),
      dataIndex: "publish_date",
      key: "publish_date",
      sortable: false,
      align: "left",
      flex: 1,
      render: (text, record) => (
        <>
          <div>
            {" "}
            {new Date(record.publish_date).toLocaleString("en-IN", {
              timeZone: "Asia/Kolkata",
              hour12: true,
            })}{" "}
          </div>
        </>
      ),
    },
  ];
  console.log("from", from_date);
  return (
    <>
      {loading ? (
        <Center mt={"45vh"}>
          <Loader
            type="spinner-default"
            bgColor={"#349481"}
            color={"#349481"}
            size={50}
          />
        </Center>
      ) : (
        <Box width="100%">
          <Text fontSize="3xl" bold pt={4} pb={4} textAlign="center">
            {ProjectName}
          </Text>

          <HStack space={"5"}>
            <Box width={"250px"}>
              <Text>{"From Date"}</Text>
              <DatePicker
                onChange={(e) => {
                  setFromDate(e?._d);
                  console.log("eeeeee", e);
                }}
              />
            </Box>
            <Box width={"250px"}>
              <Text>{"To Date"}</Text>
              <DatePicker
                onChange={(e) => {
                  setToDate(e?._d);
                  console.log("eeeeee", e);
                }}
              />
            </Box>
          </HStack>
          <Box flex={1} alignItems={"center"}>
            <HStack
              display={"flex"}
              flexWrap={"wrap"}
              justifyContent={"space-around"}
              space={10}
            >
              {items?.map((item, index) => (
                <Pressable
                  onPress={() => {
                    examDetails(item?.object_array);
                  }}
                >
                  {console.log("item", item)}

                  <VStack space={10} marginTop={"20px"}>
                    <Box
                      width="230px"
                      shadow="5"
                      height="108"
                      bgImage={bg_arr[index % 5]}
                      color="white"
                      borderRadius="5px"
                    >
                      <Text
                        display={"flex"}
                        marginTop={"12%"}
                        justifyContent={"center"}
                        bold
                        fontSize="md"
                        color="white"
                        pt={2}
                      >
                        {item?.board_name}
                      </Text>
                    </Box>
                  </VStack>
                </Pressable>
              ))}
            </HStack>

            {show_exam_list && (
              <HStack
                display={"flex"}
                flexWrap={"wrap"}
                justifyContent={"space-around"}
                marginTop={"30px"}
                space={10}
              >
                {exam_list?.map((item, index) => (
                  <Pressable
                    onPress={() => {
                      questionPaperDetails(item?.question_papers);
                    }}
                  >
                    {console.log("QuestionPaper1", item)}
                    <VStack space={10} marginTop={"20px"}>
                      <Box
                        width="230px"
                        shadow="5"
                        height="108"
                        bgImage={bg_arr[index % size]}
                        color="white"
                        borderRadius="5px"
                      >
                        <Text
                          display={"flex"}
                          marginTop={"12%"}
                          justifyContent={"center"}
                          bold
                          fontSize="md"
                          color="white"
                          pt={2}
                        >
                          {item?.exam_name}
                        </Text>
                      </Box>
                    </VStack>
                  </Pressable>
                ))}
              </HStack>
            )}

            {show_question_papers && (
              <Table
                columns={columns}
                dataSource={question_papers}
                pagination={false}
                className="dashboard-table"
              />
            )}
          </Box>
        </Box>
      )}
    </>
  );
};
export default Dashboard;

{
  /* <Pressable
            // onPress={() => {
            //   handleRoute("Inter District Camp Transfer Request");
            // }}
            >
              <Box
                width="230px"
                shadow="5"
                height="108"
                bgImage={bg_arr[1]}
                color="white"
                borderRadius="5px"
              >
                <VStack py={2} px={4}>
                  <HStack justifyContent="space-between">
                    <Text fontSize="md" color="white" pt={2}>
                      Question Paper Published Count
                    </Text>
                  </HStack>
                  <Text fontSize="3xl" bold color="white">
                    {question_paper_published_count || 0}
                  </Text>
                </VStack>
              </Box>
            </Pressable> */
}
