import React, { useEffect } from "react";
import VForm from "@views/components/ui/antd_form";
import { Form, Button } from "antd";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  useDynamicSelector,
  create_infographic_note,
  update_infographic_note,
} from "@services/redux";
import { useTranslation } from "react-i18next";
import { HStack } from "native-base";

const InfographicNotesDetails = (props) => {
  const { t } = useTranslation();
  const { initialValues, topic_id } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { loading: create_loading } = useDynamicSelector(
    "createInfographicNote"
  );
  const { loading: update_loading } = useDynamicSelector(
    "updateInfographicNote"
  );

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      ...initialValues,
      file_url: initialValues?.file_url
        ? [
            {
              uuid: "-1",
              status: "done",
              url: initialValues?.file_url,
              name: "File",
            },
          ]
        : [],
    });
  }, [initialValues]);

  const handleSubmit = (values) => {
    values.infographic_topic_id = topic_id;
    values.file_url = values.file_url?.[0]?.url;
    if (initialValues?.id) {
      let variables = {
        json: { id: initialValues?.id, data: { ...values } },
      };
      let keys = [{ key: "updateInfographicNote", loading: true }];
      dispatch(dynamicRequest(keys, update_infographic_note, variables, "M"));
    } else {
      let variables = {
        json: { data: { ...values } },
      };
      let keys = [{ key: "createInfographicNote", loading: true }];
      dispatch(dynamicRequest(keys, create_infographic_note, variables, "M"));
    }
  };

  return (
    <Form
      form={form}
      name={"Note Details"}
      layout={"vertical"}
      onFinish={handleSubmit}
      className="user_form ant-select-selector ant-select-selection-search"
    >
      <VForm.TextBox
        label={t("Name")}
        field={"name"}
        rules={[
          {
            required: true,
            message: "Name is Required",
          },
        ]}
      />
      <VForm.File
        label={t("File")}
        field={"file_url"}
        rules={[
          {
            required: true,
            message: "File is Required",
          },
        ]}
      />
      <VForm.Number
        label={t("Sort Order")}
        field={"sort_order"}
        rules={[
          {
            required: true,
            message: "Sort Order is Required",
          },
        ]}
      />
      <HStack space="10" justifyContent={"flex-end"} marginRight="40px">
        <VForm.Button
          loading={initialValues?.id ? update_loading : create_loading}
          disabled={false}
        >
          {t("Submit")}
        </VForm.Button>
        <Button onClick={props.close}>{t("close")}</Button>
      </HStack>
    </Form>
  );
};
export default InfographicNotesDetails;
