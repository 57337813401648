import React from "react";
import { Box, HStack } from "native-base";

const FooterBar = (props) => {
  return (
    <Box w="100%" bg="#ffffff" position="relative">
      <HStack h="100%" justifyContent="flex-end" marginY={"2"} mr="2">
        Copyright © {new Date().getFullYear()}
      </HStack>
    </Box>
  );
};

export default FooterBar;
