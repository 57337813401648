import React from "react";
import { Card } from "antd";
import { Box, HStack, Text } from "native-base";
import { CiCircleRemove } from "react-icons/ci";
const SortableCard = (props) => {
  const {
    selectedTopic,
    removeTopicId,
    handleSelect,
    item,
    handleRemoveTopics,
  } = props;
  return (
    <Card
      style={item?.id === selectedTopic ? { backgroundColor: "#00000042" } : {}}
      onClick={() => {
        handleSelect(item?.id);
      }}
    >
      <HStack space="3">
        <Box flex={1}>
          <Text>{item?.name}</Text>
        </Box>
        {/* <Box alignItems={"center"}>
          <CiCircleRemove
            size="25"
            color="red"
            onClick={() => {
              handleRemoveTopics(item);
            }}
          />
        </Box> */}
      </HStack>
    </Card>
  );
};
export default SortableCard;
