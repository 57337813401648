import React, { useEffect, useState } from "react";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { showToast } from "@helpers/toast";
import VForm from "@views/components/ui/antd_form";
import {
  useDynamicSelector,
  get_all_question_paper_query,
  dynamicRequest,
  dynamicClear,
  delete_question_paper_mutation,
  publish_question_paper_mutation,
  unpublish_question_paper_mutation,
  update_question_paper_mutation,
  clear_user_test_submission,
} from "@services/redux";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import Wrapper from "@views/components/ui/wrapper";
import { FcViewDetails } from "react-icons/fc";
import QuestionDetails from "./question_papers_details";
import AntdModal from "@views/components/ui/antd_modal";
import ActionButton from "@views/components/ui/dialog/action_button";
import { showNotification } from "@helpers/notify";
import {
  Pagination,
  Popconfirm,
  Table,
  Typography,
  Input,
  Button,
  Form,
  Select,
  Modal,
} from "antd";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory, useLocation, useParams } from "react-router-dom";
import PublishDate from "./publish_date";

const QuestionsList = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation();
  const { exam_id, exam_name } = useParams();
  const { t } = useTranslation();
  const [current, setCurrent] = useState(1);
  const [form] = Form.useForm();
  const [modalOpen, setModalOpen] = useState(false);
  const [actionItem, setActionItem] = useState({});
  const [visible, setVisible] = useState(false);
  const { initialValues, questionPaperId } = props;
  const [pageLimit, setPageLimit] = useState(10);
  const [actionType, setActionType] = useState(null);
  const [submissionModalOpen, setSubmissionModalOpen] = useState(false);
  const { Search } = Input;

  const {
    id: create_id,
    status: create_status,
    error: create_error,
  } = useDynamicSelector("create_question_paper");

  const { status: update_status } = useDynamicSelector("update_question_paper");
  const { status: schedule_update_status } = useDynamicSelector(
    "scheduleQuestionPaper"
  );

  const {
    loading: deleteQuestionPaperLoading,
    status: deleteQuestionPaperStatus,
    error: deleteQuestionPaperError,
  } = useDynamicSelector("delete_question_paper");

  const handleAdd = () => {
    history.push(`${ROUTES.QUESTIONS_DETAILS}/${exam_id}`);
  };
  const {
    items: questions,
    loading,
    pagination,
    total_items,
  } = useDynamicSelector("get_all_question");

  const getAllQuestionPaper = () => {
    let keys = [{ key: "get_all_question", loading: true }];
    let query = get_all_question_paper_query;
    let variables = {
      page_number: current,
      page_limit: pageLimit,
      exam_id,
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  const onShowSizeChange = (current, value) => {
    let keys = [{ key: "get_all_question", loading: true }];
    let query = get_all_question_paper_query;
    let variables = {
      page_number: current,
      page_limit: value,
      exam_id,
    };
    dispatch(dynamicRequest(keys, query, variables));
  };
  const fetchData = (value) => {
    let keys = [{ key: "get_all_question", loading: true, exam_id }];
    let query = get_all_question_paper_query;
    let variables = {
      page_number: value,
      page_limit: pageLimit,
      exam_id,
    };
    dispatch(dynamicRequest(keys, query, variables));
    setCurrent(value);
  };
  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    let keys = [{ key: "clearUserTestSubmission", loading: true }];
    dispatch(dynamicRequest(keys, clear_user_test_submission));
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
  const { status: createStatus, error: createError } =
    useDynamicSelector("create_question");

  const {
    status: clearUserTestSubmission,
    error: clearUserTestSubmissionerror,
  } = useDynamicSelector("clearUserTestSubmission");

  const {
    status: publishQuestionPaperStatus,
    error: publishQuestionPaperError,
  } = useDynamicSelector("publish_question_paper");

  const {
    status: unpublishQuestionPaperStatus,
    error: unpublishQuestionPaperError,
  } = useDynamicSelector("unpublish_question_paper");

  const { status: updateStatus, error: updateError } =
    useDynamicSelector("update_question");

  const {
    loading: deleteLoading,
    status: deleteStatus,
    error: deleteError,
  } = useDynamicSelector("delete_question");

  const handleSearch = (search_txt) => {
    dispatch(
      dynamicRequest(keys, get_all_candidates_query, {
        search_string: search_txt,
      })
    );
  };

  const handlePublish = (id) => {
    let variables = {
      id: id,
      is_published: true,
    };
    let keys = [{ key: "publish_question_paper", loading: true }];
    dispatch(
      dynamicRequest(keys, publish_question_paper_mutation, variables, "M")
    );
  };
  const handleUnPublish = (id) => {
    let variables = {
      id: id,
      is_published: false,
    };
    let keys = [{ key: "unpublish_question_paper", loading: true }];
    dispatch(
      dynamicRequest(keys, unpublish_question_paper_mutation, variables, "M")
    );
  };

  const handleSubmit = (id, record) => {
    let variables = {
      id: id,
      data: { allow_submission: true, title: record.title },
    };
    let keys = [{ key: "update_question_paper", loading: true }];
    dispatch(
      dynamicRequest(keys, update_question_paper_mutation, variables, "M")
    );
  };
  const handleUnSubmit = (id, record) => {
    let variables = {
      id: id,
      data: { allow_submission: false, title: record.title },
    };
    let keys = [{ key: "update_question_paper", loading: true }];
    dispatch(
      dynamicRequest(keys, update_question_paper_mutation, variables, "M")
    );
  };

  const handleEdit = (item) => {
    setActionType("edit");
    history.push(`${ROUTES.QUESTIONS_DETAILS}/${exam_id}/${item.id}`);
  };

  const handleDelete = (record) => {
    let keys = [{ key: "delete_question_paper", loading: true, exam_id }];
    dispatch(
      dynamicRequest(keys, delete_question_paper_mutation, {
        id: record?.id,
      })
    );
  };

  const handleResponse = (data) => {
    history.push({
      pathname: `${ROUTES.RESPONSES_LIST}/${data.id}`,
      state: data,
    });
  };

  const handleSubmissionDateModal = (item, data) => {
    setSubmissionModalOpen(true);
    setActionItem(data);
  };
  const handleSubmissionDateModalClose = () => {
    setSubmissionModalOpen(false);
  };
  useEffect(() => {
    if (publishQuestionPaperStatus === "success") {
      showNotification({
        type: "success",
        message: `${"Question"} Paper Published successfully`,
      });
      let keys = [{ key: "get_all_question", loading: true, exam_id }];
      let variables = {
        page_number: current,
        page_limit: pageLimit,
        exam_id,
      };
      dispatch(dynamicRequest(keys, get_all_question_paper_query, variables));
      dispatch(dynamicClear("publish_question_paper"));
    }
  }, [publishQuestionPaperStatus]);

  useEffect(() => {
    if (clearUserTestSubmission === "success") {
      showNotification({
        type: "success",
        message: `${"Success"} `,
      });
      // let keys = [{ key: "get_all_question", loading: true }];

      // dispatch(dynamicRequest(keys, get_all_question_paper_query));
      dispatch(dynamicClear("clearUserTestSubmission"));
    }
  }, [clearUserTestSubmission]);

  useEffect(() => {
    if (unpublishQuestionPaperStatus === "success") {
      showNotification({
        type: "success",
        message: `${"Question"} Paper Unpublished successfully`,
      });
      let keys = [{ key: "get_all_question", loading: true, exam_id: exam_id }];
      let variables = {
        page_number: current,
        page_limit: pageLimit,
        exam_id,
      };
      dispatch(dynamicRequest(keys, get_all_question_paper_query, variables));
      dispatch(dynamicClear("unpublish_question_paper"));
    }
  }, [unpublishQuestionPaperStatus]);

  useEffect(() => {
    if (update_status === "success") {
      showNotification({
        type: "success",
        message: t("question_paper_update_success_msg"),
      });
      handleSubmissionDateModalClose();
      getAllQuestionPaper();
    } else if (update_status === "failure") {
      showNotification({
        type: "success",
        message: t("question_paper_update_failure_msg"),
      });
      getAllQuestionPaper();
    }
  }, [update_status]);

  useEffect(() => {
    if (schedule_update_status === "success") {
      showNotification({
        type: "success",
        message: t("question_paper_update_success_msg"),
      });
      handleSubmissionDateModalClose();
      getAllQuestionPaper();
    } else if (schedule_update_status === "failure") {
      showNotification({
        type: "success",
        message: t("question_paper_update_failure_msg"),
      });
      getAllQuestionPaper();
    }
  }, [schedule_update_status]);

  const handleclearUserTestSubmission = () => {};

  useEffect(() => {
    if (actionType === "add" && createStatus === "success") {
      handleModalClose();
      showNotification({
        type: "success",
        message: `${"Question"} added successfully`,
      });
      let keys = [{ key: "get_question", loading: true }];

      dispatch(dynamicRequest(keys, get_all_subject_query, "M"));
      dispatch(dynamicClear("create_question"));
    } else if (actionType === "edit" && updateStatus === "success") {
      handleModalClose();
      showNotification({
        type: "success",
        message: `${"Question"} Updated successfully`,
      });
      dispatch(dynamicClear("update_question"));

      let keys = [{ key: "get_question", loading: true }];

      dispatch(dynamicRequest(keys, get_all_subject_query));
    } else if (deleteStatus === "success") {
      showNotification({
        type: "success",
        message: `${"question"} deleted successfully`,
      });
      dispatch(dynamicClear("create_question"));

      let keys = [{ key: "delete_question", loading: true }];

      dispatch(dynamicRequest(keys, get_question_paper, "M"));
    }
  }, [createStatus, updateStatus, deleteStatus]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (create_error) {
      showToast({
        type: "Failure",
        message: create_error,
      });
    }
    if (deleteQuestionPaperStatus === "success") {
      showToast({
        type: "success",
        message: `Question paper deleted  successfully`,
      });
      dispatch(dynamicClear("delete_question_paper"));
      let keys = [{ key: "delete_question_paper", loading: true }];
      dispatch(
        dynamicRequest(keys, get_all_question_paper_query, {
          page_number: current,
          page_limit: 10,
          exam_id,
        })
      );
    }
  }, [create_error, deleteQuestionPaperStatus]);

  useEffect(() => {
    getAllQuestionPaper();
  }, []);

  let header_actions = [
    {
      height: "50px",
      label: t("Clear User Test Submission"),
      colorScheme: "primary",
      variant: "outline",
      onPress: showModal,
    },
    {
      height: "50px",
      label: t("Add Question Paper"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];

  let columns = [
    {
      title: "S NO",
      key: "sno",
      width: "20px",
      render: (value, item, index) => {
        return (current - 1) * 10 + index + 1;
      },
    },
    {
      title: t("table:Title"),
      dataIndex: "",
      key: "title",
      sortable: false,
      align: "left",
      flex: 1,
      render: (data) => (
        <Pressable onPress={() => handleResponse(data)}>
          <Text color={"#0000ff"}>{data.title}</Text>
        </Pressable>
      ),
    },

    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      render: (record) => {
        return (
          <span>
            <Typography.Link
              onClick={() => handleEdit(record)}
              style={{ marginRight: 15 }}
            >
              <AiOutlineEdit />
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record)}
            >
              <AiOutlineDelete style={{ cursor: "pointer", color: "Red" }} />
            </Popconfirm>
            {record.is_published ? (
              <Button
                style={{ marginLeft: 15 }}
                onClick={() => handleUnPublish(record.id)}
              >
                Unpublish
              </Button>
            ) : (
              <Button
                style={{ marginLeft: 15 }}
                onClick={() => handlePublish(record.id)}
              >
                Publish
              </Button>
            )}
            {record.allow_submission ? (
              <Button
                style={{ marginLeft: 15 }}
                onClick={() => handleUnSubmit(record.id, record)}
              >
                Refuse Submission
              </Button>
            ) : (
              <Button
                style={{ marginLeft: 15 }}
                onClick={() => handleSubmit(record.id, record)}
              >
                Allow Submission
              </Button>
            )}
            {/* {!record.allow_submission && ( */}
            <Button
              style={{ marginLeft: 15 }}
              onClick={() => handleSubmissionDateModal(record.id, record)}
            >
              Publish Date
            </Button>
            {/* )} */}
          </span>
        );
      },
    },
  ];

  return (
    <>
      <Box top="10px" zIndex="2" bg="white">
        <Text fontSize="md" fontWeight="bold">
          {exam_name}
          {" Question Papers"}
        </Text>
      </Box>

      <Box width="100%" mt="10px" marginBottom="20px">
        <VStack space={5}>
          <HStack space={3} justifyContent="flex-end" m={3}>
            {/* <Box marginTop={"10px"}>
              <Search placeholder="search" allowClear onSearch={handleSearch} />
            </Box> */}
            {header_actions.map((headerAction, index) => (
              <Box key={`header-action-${index}`}>
                <HStack space={5}>
                  <ActionButton {...headerAction} />
                </HStack>
              </Box>
            ))}
          </HStack>
          <Wrapper>
            <Table
              columns={columns}
              dataSource={questions}
              loading={loading}
              pagination={false}
            />
          </Wrapper>
          <Pagination
            total={pagination?.total_count}
            showSizeChanger={true}
            onShowSizeChange={onShowSizeChange}
            current={current}
            hideOnSinglePage={false}
            onChange={fetchData}
          />
          <HStack justifyContent={"flex-end"}>
            <Button
              type="default"
              danger
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Button>
          </HStack>
        </VStack>
      </Box>
      <AntdModal
        isOpen={modalOpen}
        onClose={handleModalClose}
        header={actionType === "add" ? t("Add Question") : t("Update Question")}
        component={
          <QuestionDetails
            label={actionType === "add" ? t("add") : t("update")}
            close={handleModalClose}
            initialValues={actionItem}
          />
        }
      />
      <Modal
        title="Clear User Test Submission"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure you want to delete this user?</p>
      </Modal>
      <AntdModal
        isOpen={submissionModalOpen}
        onClose={handleSubmissionDateModalClose}
        header={"Publish Date"}
        width="400px"
        component={
          <PublishDate
            label={actionType === "add" ? t("add") : t("update")}
            close={handleSubmissionDateModalClose}
            initialValues={actionItem}
          />
        }
      />
    </>
  );
};
export default QuestionsList;
