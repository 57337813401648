import React, { useEffect, useState } from "react";
import { Box, Button, HStack, Text, VStack, Pressable } from "native-base";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { showToast } from "@helpers/toast";
import AntdModal from "@views/components/ui/antd_modal";

import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import Wrapper from "@views/components/ui/wrapper";
import { Pagination, Popconfirm, Table, Typography } from "antd";
import { useHistory } from "react-router-dom";
import InfographicTopicDetails from "./infographic_topics_details";
import {
  delete_infographic_topic,
  dynamicClear,
  dynamicRequest,
  get_all_infographic_topics,
  useDynamicSelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";

const InfographicTopicsList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [actionItem, setActionItem] = useState({});

  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [actionType, setActionType] = useState(null);

  const {
    items: infographic_topics,
    pagination,
    loading,
  } = useDynamicSelector("getInfographicTopics");
  const { status: create_status, error: create_error } = useDynamicSelector(
    "createInfographicTopic"
  );
  const { status: update_status, error: update_error } = useDynamicSelector(
    "updateInfographicTopic"
  );
  const {
    loading: delete_loading,
    status: delete_status,
    error: delete_error,
  } = useDynamicSelector("deleteInfographicTopic");
  const handleAdd = () => {
    setActionItem({});
    setActionType("add");
    setModalOpen(true);
  };

  const handleEdit = (item) => {
    setActionItem(item);
    setActionType("edit");
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleGetInfographicTopics = () => {
    let keys = [{ key: "getInfographicTopics", loading: true }];
    let query = get_all_infographic_topics;
    let variables = { page_number: current, page_limit: current_limit };
    dispatch(dynamicRequest(keys, query, variables));
  };

  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "getInfographicTopics", loading: true }];
    let query = get_all_infographic_topics;
    let variables = { page_number, page_limit };
    dispatch(dynamicRequest(keys, query, variables));
    setCurrent(page_number);
    setCurrentLimit(page_limit);
  };

  const handleDelete = (record) => {
    let keys = [{ key: "deleteInfographicTopic", loading: true }];
    dispatch(
      dynamicRequest(keys, delete_infographic_topic, {
        json: { id: record?.id },
      })
    );
  };
  const handleRedirectNotes = (item) => {
    history.push({ pathname: `${ROUTES.INFOGRAPHIC_NOTES_LIST}/${item?.id}` });
  };

  useEffect(() => {
    handleGetInfographicTopics();
  }, []);

  useEffect(() => {
    if (create_error) {
      showToast({
        type: "error",
        message: "Something went wrong",
      });
      dispatch(dynamicClear("createInfographicTopic"));
    } else if (create_status === "success") {
      showToast({
        type: "success",
        message: "Infographic topics created successfully",
      });
      handleModalClose();
      handleGetInfographicTopics();
      dispatch(dynamicClear("createInfographicTopic"));
    } else if (update_error) {
      showToast({
        type: "error",
        message: "Something went wrong",
      });
      dispatch(dynamicClear("updateInfographicTopic"));
    } else if (update_status === "success") {
      showToast({
        type: "success",
        message: "Infographic topics updated successfully",
      });
      handleModalClose();
      handleGetInfographicTopics();
      dispatch(dynamicClear("updateInfographicTopic"));
    }
  }, [create_error, create_status, update_error, update_error]);

  useEffect(() => {
    if (delete_error) {
      showToast({
        type: "error",
        message: "Something went wrong",
      });
      dispatch(dynamicClear("deleteInfographicTopic"));
    } else if (delete_status === "success") {
      showToast({
        type: "success",
        message: "Infographic topics deleted successfully",
      });
      handleModalClose();
      handleGetInfographicTopics();
      dispatch(dynamicClear("deleteInfographicTopic"));
    }
  }, [delete_error, delete_status]);
  let columns = [
    {
      title: t("table:name"),
      dataIndex: "",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return (
          <Pressable
            onPress={() => {
              handleRedirectNotes(record);
            }}
          >
            <Text color={"blue.600"}>{record?.name}</Text>
          </Pressable>
        );
      },
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      render: (record) => {
        return (
          <span>
            <Typography.Link
              onClick={() => handleEdit(record)}
              style={{ marginRight: 15 }}
            >
              <AiOutlineEdit />
              {/* <Text color="blue.400">Edit</Text> */}
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record)}
            >
              <AiOutlineDelete />
              {/* <Text color="red.400">
                  <a>Delete</a>
                </Text> */}
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  return (
    <>
      <Box position="sticky" top="10px" zIndex="2" bg="white">
        <Text fontSize="md" fontWeight="bold">
          {t("infographic_topics")}
        </Text>
      </Box>
      <Box width="100%" mt="10px" marginBottom="20px">
        <VStack space={5}>
          <HStack space={3} justifyContent="flex-end" m={3}>
            <Box key={`header-action`}>
              <Button type="primary" onPress={handleAdd}>
                {t("add_infographic_topic")}
              </Button>
            </Box>
          </HStack>
          <Wrapper>
            <Table
              loading={loading}
              columns={columns}
              dataSource={infographic_topics}
              pagination={false}
            />
          </Wrapper>
          <Pagination
            total={pagination?.total_count}
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            onChange={fetchData}
          />
        </VStack>
      </Box>
      <AntdModal
        isOpen={modalOpen}
        onClose={handleModalClose}
        width={450}
        header={
          actionType === "add"
            ? t("add_infographic_topic")
            : t("edit_infographic_topic")
        }
        component={
          <InfographicTopicDetails
            label={"add"}
            close={handleModalClose}
            initialValues={actionItem}
          />
        }
      />
    </>
  );
};
export default InfographicTopicsList;
