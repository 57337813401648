import { gql } from "@apollo/client";

export const publish_question_paper_mutation = gql`
  mutation publishQuestionpaper($id: String!, $is_published: Boolean) {
    publish_question_paper: publishQuestionPaper(
      id: $id
      is_published: $is_published
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const unpublish_question_paper_mutation = gql`
  mutation publishQuestionpaper($id: String!, $is_published: Boolean) {
    unpublish_question_paper: publishQuestionPaper(
      id: $id
      is_published: $is_published
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
