import React, { useEffect, useState } from "react";
import { Box, Button, HStack, Text, VStack, Pressable } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { showToast } from "@helpers/toast";
// import ActionButton from "@views/components/ui/table/action_button";
import AntdModal from "@views/components/ui/antd_modal";
import ActionButton from "@views/components/ui/dialog/action_button";
import TopicDetails from "./topic_details";
import { showNotification } from "@helpers/notify";
import {
  create_topic_mutation,
  update_topic_mutation,
  get_all_topics_query,
  get_one_topic_query,
  useDynamicSelector,
  dynamicRequest,
  delete_topic_mutation,
  dynamicClear,
} from "@services/redux";
import { AiOutlineEdit, AiOutlineDelete, AiFillYoutube } from "react-icons/ai";
import Wrapper from "@views/components/ui/wrapper";
import { Pagination, Popconfirm, Table, Typography } from "antd";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";

const TopicList = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [actionItem, setActionItem] = useState({});
  const [dialogVisible, setDialogVisible] = useState(false);
  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [actionType, setActionType] = useState(null);
  const { items: get_all_topics, pagination } =
    useDynamicSelector("get_all_topics");

  const { status: createStatus, error: createError } =
    useDynamicSelector("create_topic");
  const { status: updateStatus, error: updateError } =
    useDynamicSelector("update_topic");

  const {
    loading: deleteLoading,
    status: deleteStatus,
    error: deleteError,
  } = useDynamicSelector("delete_topic");

  const handleAdd = () => {
    setActionItem({});
    setActionType("add");
    setModalOpen(true);
  };

  const handleEdit = (item) => {
    setActionItem(item);
    setActionType("edit");
    setModalOpen(true);
  };

  const handleDelete = (record) => {
    let keys = [{ key: "delete_topic", loading: true }];
    dispatch(
      dynamicRequest(keys, delete_topic_mutation, {
        id: record?.id,
      })
    );
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleRecordPerformance = () => {
    a.href = dataIndex;
  };

  useEffect(() => {
    let keys = [{ key: "get_all_topics", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_topics_query, {
        page_number: current,
        page_limit: current_limit,
      })
    );
  }, []);
  const loadExams = (should_load) => {
    let keys = [{ key: "get_all_topics", loading: should_load }];
    dispatch(
      dynamicRequest(keys, get_all_topics_query, {
        page_number: current,
        page_limit: current_limit,
      })
    );
  };
  useEffect(() => {
    if (actionType === "add" && createStatus === "success") {
      handleModalClose();
      showNotification({
        type: "success",
        message: `${"Topic"} added successfully`,
      });
      let keys = [{ key: "get_all_topics", loading: true }];

      dispatch(dynamicRequest(keys, get_all_topics_query));
      dispatch(dynamicClear("create_topic"));
    } else if (actionType === "edit" && updateStatus === "success") {
      handleModalClose();

      showNotification({
        type: "success",
      });
      dispatch(dynamicClear("update_topic"));

      let keys = [{ key: "get_all_topics", loading: true }];

      dispatch(dynamicRequest(keys, get_all_topics_query));
    } else if (deleteStatus === "success") {
      showNotification({
        type: "success",
        message: `${"Topic"} deleted successfully`,
      });
      dispatch(dynamicClear("delete_topic"));

      let keys = [{ key: "get_all_topics", loading: true }];

      dispatch(dynamicRequest(keys, get_all_topics_query));
    }
  }, [createStatus, updateStatus, deleteStatus]);

  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "get_all_topics", loading: true }];
    dispatch(
      dynamicRequest(keys, get_all_topics_query, {
        page_number: page_number,
        page_limit: page_limit,
      })
    );
    setCurrent(page_number);
    setCurrentLimit(page_limit);
  };
  useEffect(() => {
    loadExams(true);
  }, []);

  const handleResponse = (data) => {
    history.push({
      pathname: `${ROUTES.NOTE_LIST}/${data.id}`,
      state: data,
    });
  };

  let columns = [
    {
      title: t("table:Name"),
      dataIndex: "",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
      render: (data) => (
        <Pressable onPress={() => handleResponse(data)}>
          <Text color={"#0000ff"}>{data.name}</Text>
        </Pressable>
      ),
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      render: (record) => {
        return (
          <span>
            <Typography.Link
              onClick={() => handleEdit(record)}
              style={{ marginRight: 15 }}
            >
              <AiOutlineEdit />
              {/* <Text color="blue.400">Edit</Text> */}
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record)}
            >
              <AiOutlineDelete />
              {/* <Text color="red.400">
                <a>Delete</a>
              </Text> */}
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  return (
    <>
      <Box position="sticky" top="10px" zIndex="2" bg="white">
        <Text fontSize="md" fontWeight="bold">
          {"Topics"}
        </Text>
      </Box>
      <Box width="100%" mt="10px" marginBottom="20px">
        <VStack space={5}>
          <HStack space={3} justifyContent="flex-end" m={3}>
            <Box key={`header-action`}>
              <Button type="primary" onPress={handleAdd}>
                Add Topic
              </Button>
            </Box>
          </HStack>
          <Wrapper>
            <Table
              columns={columns}
              dataSource={get_all_topics}
              pagination={false}
            />
          </Wrapper>
          <Pagination
            total={pagination?.total_count}
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            onChange={fetchData}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} topics`
            }
          />
        </VStack>
      </Box>
      <AntdModal
        isOpen={modalOpen}
        onClose={handleModalClose}
        width={450}
        header={actionType === "add" ? t("Add Topic") : t("Update Topic")}
        component={
          <TopicDetails
            label={actionType === "add" ? t("add") : t("update")}
            close={handleModalClose}
            initialValues={actionItem}
          />
        }
      />
    </>
  );
};
export default TopicList;
