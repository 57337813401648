import { gql } from "@apollo/client";


export const create_text_book_board_mutation = gql`
  mutation createTextBookBoard($data: text_book_board_input) {
    create_text_book_board: createTextBookBoard(data: $data) {
      id
      status
      count
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_text_book_board_mutation = gql`
  mutation deleteTextBookBoard($id: String!) {
    delete_text_book_board: deleteTextBookBoard(id: $id) {
      id
      status
      count
      error {
        status_code
        message
      }
    }
  }
`;

export const update_text_book_board_mutation = gql`
  mutation updateTextBookBoard($id: String!, $data: text_book_board_input) {
    update_text_book_board: updateTextBookBoard(id: $id, data: $data) {
      id
      status
      count
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_text_book_board_query = gql`
  query getAllTextBookBoards($page_number: Float, $page_limit: Float) {
    get_all_text_book_board: getAllTextBookBoards(page_number: $page_number, page_limit: $page_limit) {
      items{
        id
        name
        icon
        sort_order
      }
      pagination{
        page_limit
        page_number
        total_count
      }
      error{
        status_code
        message
      }
    }
  }
`;

export const get_one_text_book_board_query = gql`
  query getTextBookBoard($id: String!) {
    get_one_text_book_board: getTextBookBoard(id: $id) {
        id
        name
        icon
        sort_order
      error{
        status_code
        message
      }
    }
  }
`;