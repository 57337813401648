import { gql } from "@apollo/client";

export const get_report_query = gql`
  query getReport {
    getReport {
      district_wise_data {
        district_name
        user_count
      }
      subscribers_month_wise_data {
        year_month
        first_half_count
        second_half_count
      }
      exam_wise_test_taken_data {
        id
        name
        monthly_stats {
          year_month
          first_half_count
          second_half_count
        }
      }
      notes_download_data {
        exam_id
        name
        monthly_stats {
          year_month
          first_half_count
          second_half_count
        }
      }
      video_view_data {
        exam_id
        name
        monthly_stats {
          year_month
          first_half_count
          second_half_count
        }
      }
      notification_data {
        exam_id
        name
        monthly_stats {
          year_month
          first_half_count
          second_half_count
        }
      }
      syllabus_view_data {
        exam_id
        name
        monthly_stats {
          year_month
          first_half_count
          second_half_count
        }
      }
      syllabus_download_data {
        exam_id
        name
        monthly_stats {
          year_month
          first_half_count
          second_half_count
        }
      }

      class_schedule_view_data {
        exam_id
        name
        monthly_stats {
          year_month
          first_half_count
          second_half_count
        }
      }
      class_schedule_download_data {
        exam_id
        name
        monthly_stats {
          year_month
          first_half_count
          second_half_count
        }
      }

      test_schedule_view_data {
        exam_id
        name
        monthly_stats {
          year_month
          first_half_count
          second_half_count
        }
      }
      test_schedule_download_data {
        exam_id
        name
        monthly_stats {
          year_month
          first_half_count
          second_half_count
        }
      }

      previous_year_question_paper_download_data {
        exam_id
        name
        monthly_stats {
          year_month
          first_half_count
          second_half_count
        }
      }

      previous_year_question_paper_view_data {
        exam_id
        name
        monthly_stats {
          year_month
          first_half_count
          second_half_count
        }
      }

      test_wise_submission_count_for_each_exam_data {
        exam_id
        exam_name
        question_paper_name
        monthly_submission_stats {
          year_month
          first_half_count
          second_half_count
        }
      }
    }
  }
`;

export const get_candidate_report = gql`
  query getCandidatesReport($page_number: Int, $page_limit: Int) {
    getCandidatesReport(page_number: $page_number, page_limit: $page_limit) {
      items {
        id
        name
        dob
        gender
        city
        district_name
        state_name
        email
        mobile
      }
    }
  }
`;
