import { gql } from "@apollo/client";

export const get_all_notifications = gql`
  query getNotifications($json: get_all_notification_input) {
    getNotifications(json: $json) {
      items {
        id
        type
        datetime
        title
        description
        question_paper {
          id
          title
          allowed_time
        }
        board {
          id
          name
        }
        exam {
          id
          name
        }
        note {
          id
          title
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
