import React, { useState } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Divider, Button, Select, Input, InputNumber, Card } from "antd";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  HStack,
  VStack,
  Text,
  Pressable,
  useBreakpointValue,
} from "native-base";
import VForm from "@views/components/ui/antd_form";
import { showNotification } from "@helpers/notify";
import {
  dynamicRequest,
  useDynamicSelector,
  assign_evaluator_mutation,
  get_all_responses_query,
  get_all_evaluators_query,
} from "@services/redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

function DynamicField(props) {
  const [form] = Form.useForm();
  const { initialValues, selectedRowKeys, close } = props;
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    items: get_all_responses,
    loading,
    total_count,
  } = useDynamicSelector("get_all_responses");
  const [selectedList, setSelectedList] = useState([]);
  const [evaluator_list, setEvaluatorList] = useState([]);
  const { items: evaluators } = useDynamicSelector("get_all_evaluators");
  const columns = useBreakpointValue({
    base: 1,
    md: 1,
  });

  const { t } = useTranslation();
  const handleChange = (b, a) => {};
  const {
    status: assignEvaluatorStatus,
    error: setEvaluatorError,
    loading: assignEvaluatorLoading,
  } = useDynamicSelector("assign_evaluator");

  const handleSubmit = (id) => {
    let variables = {
      data: {
        evaluator_id: id.evaluater_id,
        user_test_submission_ids: selectedRowKeys,
      },
    };
    let keys = [{ key: "assign_evaluator", loading: true }];
    dispatch(dynamicRequest(keys, assign_evaluator_mutation, variables, "M"));
  };

  useEffect(() => {
    let keys = [{ key: "get_all_evaluators", loading: true }];
    dispatch(dynamicRequest(keys, get_all_evaluators_query, {}));
  }, []);
  useEffect(() => {
    if (evaluators?.length)
      setEvaluatorList(
        evaluators.map((x) => ({
          id: x.id,
          name: `${x.name} - ${x.system_user.email}`,
        }))
      );
  }, [evaluators]);
  useEffect(() => {
    let filteredArray = [];
    filteredArray = get_all_responses.filter((p) => {
      return selectedRowKeys.some((x) => {
        return x === p.id;
      });
    });
    setSelectedList(filteredArray);
  }, [selectedRowKeys]);

  useEffect(() => {
    if (assignEvaluatorStatus === "success") {
      showNotification({
        type: "success",
        message: `${"Evaluator"} Assigned successfully`,
      });
      let keys = [{ key: "get_all_responses", loading: true }];
      let variables = {
        question_paper_id: id,
      };
      dispatch(dynamicRequest(keys, get_all_responses_query, variables));
      close();
    }
  }, [assignEvaluatorStatus]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);
  return (
    <Box>
      <Box>
        <Card>
          <HStack>
            <Text> Assign Evaluator For</Text>
            {selectedList?.map((x, i) => {
              return (
                <Text>
                  {i ? "," : ""}{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {x?.user?.name}
                  </span>
                </Text>
              );
            })}
          </HStack>
        </Card>
      </Box>
      <Box width="100%">
        <Form
          form={form}
          name={"Add Question"}
          layout={"vertical"}
          onFinish={handleSubmit}
          onValuesChange={handleChange}
          className="user_form ant-select-selector ant-select-selection-search"
        >
          <VForm.Grid columns={columns} spacingY={20} spacingX={10}>
            <VForm.Select
              label={t("Evaluator")}
              field={"evaluater_id"}
              options={evaluator_list}
              isSearchable
            />
            <Box></Box>
          </VForm.Grid>
          <HStack space="10" justifyContent={"flex-end"} marginRight="40px">
            <VForm.Button
              disabled={false}
              //  loading={assignEvaluatorLoading }
              isLoading={assignEvaluatorLoading}
            >
              {t("Submit")}
            </VForm.Button>
            <Button onClick={props.close}>{t("close")}</Button>
          </HStack>
        </Form>
      </Box>
    </Box>
  );
}

export default DynamicField;
