import React, { useState } from "react";
import { SettingOutlined } from "@ant-design/icons";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { GrAddCircle } from "react-icons/gr";
import { Collapse, Select } from "antd";

const { Panel } = Collapse;
const { Option } = Select;



// type ExpandIconPosition = 'start' | 'end';

const Accordian = (props) => {
  const [expandIconPosition, setExpandIconPosition] = useState("start");

  const onChange = (key) => {
  };

  return (
    <>
      <Collapse
        defaultActiveKey={props?.data?.[0]?.id}
        onChange={props.onChange}
        isOpen={expandIconPosition}
        accordion
      >
        {props?.data?.map((x,i)=>
        <Panel header={x[props?.headerField]} key={x.id} extra={props.headerButtons(x)}>
          <div>{props.itemComponent}</div>
        </Panel>)}
      </Collapse>
    </>
  );
};

export default Accordian;
