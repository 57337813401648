import React, { useEffect, useState } from "react";
import { Box, HStack, Text, VStack } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AntdModal from "@views/components/ui/antd_modal";
import { showNotification } from "@helpers/notify";
import {
  play_list_videos_query,
  useDynamicSelector,
  dynamicRequest,
  get_one_exam_play_list_query,
} from "@services/redux";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import Wrapper from "@views/components/ui/wrapper";
import { Pagination, Popconfirm, Table, Typography, Button } from "antd";
import { useHistory, useParams } from "react-router-dom";
import ExamVideoPlayListDetails from "./exam_video_play_list_details";

const VideosList = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);
  const { t } = useTranslation();

  const {
    items: play_list_videos,
    pagination,
    loading,
  } = useDynamicSelector("play_list_videos");
  const { name } = useDynamicSelector("get_one_exam_play_list");
  const loadExams = (should_load) => {
    let keys = [{ key: "play_list_videos", loading: should_load }];
    dispatch(
      dynamicRequest(keys, play_list_videos_query, {
        page_number: current,
        page_limit: current_limit,
        exam_playlist_id: id,
      })
    );
  };

  const get_one_play_list = () => {
    let keys = [{ key: "get_one_exam_play_list" }];
    dispatch(
      dynamicRequest(keys, get_one_exam_play_list_query, {
        id: id,
      })
    );
  };
  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "play_list_videos", loading: true }];
    dispatch(
      dynamicRequest(keys, play_list_videos_query, {
        page_number: page_number,
        page_limit: page_limit,
        exam_playlist_id: id,
      })
    );
    setCurrent(page_number);
    setCurrentLimit(page_limit);
  };
  useEffect(() => {
    loadExams(true);
    get_one_play_list();
  }, []);

  let columns = [
    {
      title: t("table:Name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:video_view_count"),
      dataIndex: "video_view_count",
      key: "video_view_count",
      sortable: false,
      align: "left",
      flex: 1,
    },
  ];

  return (
    <>
      <Box top="10px" zIndex="2" py="5">
        <Text fontSize="md" fontWeight="bold">
          {name}
        </Text>
      </Box>
      <Box width="100%" mt="10px" marginBottom="20px">
        <VStack space={5}>
          <Wrapper>
            <Table
              loading={loading}
              columns={columns}
              dataSource={play_list_videos}
              pagination={false}
            />
          </Wrapper>
          {play_list_videos?.length && (
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              current={current}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          )}

          <HStack justifyContent={"flex-end"}>
            <Button
              type="default"
              danger
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Button>
          </HStack>
        </VStack>
      </Box>
    </>
  );
};
export default VideosList;
