import { gql } from "@apollo/client";

export const get_all_previous_year_question_paper_query = gql`
  query getAllPreviousYearQuestionPapers($exam_id: String!) {
    get_all_previous_year_question_papers: getAllPreviousYearQuestionPapers(
      exam_id: $exam_id
    ) {
      items {
        id
        question_paper_url
        exam_id
        year
      }
    }
  }
`;

export const get_one_previous_year_question_paper_query = gql`
  query getPreviousYearQuestionPaper($id: String) {
    get_one_previous_year_question_paper: getPreviousYearQuestionPaper(
      id: $id
    ) {
      id
      question_paper_url
      exam_id
      year
    }
  }
`;
export const create_previous_year_question_paper_mutation = gql`
  mutation createPreviousYearQuestionPaper(
    $data: previous_year_question_paper_input
  ) {
    create_previous_year_question_paper: createPreviousYearQuestionPaper(
      data: $data
    ) {
      id
      status
      count
      error {
        status_code
        message
      }
    }
  }
`;
export const update_previous_year_question_paper_mutation = gql`
  mutation updatePreviousYearQuestionPaper(
    $id: String!
    $data: previous_year_question_paper_input
  ) {
    update_previous_year_question_paper: updatePreviousYearQuestionPaper(
      id: $id
      data: $data
    ) {
      id
      status
      count
      error {
        status_code
        message
      }
    }
  }
`;
