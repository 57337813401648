import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Pressable, useBreakpointValue } from "native-base";
import { Divide as Hamburger } from "hamburger-react";
import {
  ProSidebar,
  Menu,
  MenuItem as ProSidebarMenuItem,
  SubMenu as ProSidebarSubMenu,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { useEffect } from "react";
import { FaGem, FaHeart } from "react-icons/fa";

let defaultStyles = {
  burgerColor: "#7199ff",
  backColor: "#ff4a4a",
  headerHeight: "200px",
  footerHeight: "100px",
  titleColor: "#adadad",
  titleHoverColor: "#ffffff",
  titleActiveColor: "#ffffff",
  iconShape: "square",
  iconBgColor: "#2b2b2b",
  iconColor: "#ff0000",
  iconSize: 15,
  iconActiveColor: "#ffffff",
};

const SideBar = (props) => {
  let sidebarToggle = localStorage.getItem("sidebarToggle");
  const [open, setOpen] = useState(sidebarToggle === "true" ? true : false);
  const isCollapsible = useBreakpointValue({
    base: false,
    xs: false,
    sm: false,
    md: false,
    lg: true,
    xl: true,
  });
  useEffect(() => {
    if (open !== undefined) {
      localStorage.setItem("sidebarToggle", `${open}`);
    }
  }, [open]);

  let burgerColor = props.burgerColor || defaultStyles.burgerColor;
  let backColor = props.backColor || defaultStyles.backColor;
  let headerHeight = props.headerHeight || defaultStyles.headerHeight;
  let footerHeight = props.footerHeight || defaultStyles.footerHeight;
  let titleColor = props.titleColor || defaultStyles.titleColor;
  let titleHoverColor = props.titleHoverColor || defaultStyles.titleHoverColor;
  let titleActiveColor =
    props.titleActiveColor || defaultStyles.titleActiveColor;
  let iconShape = props.iconShape || defaultStyles.iconShape;
  let iconBgColor = props.iconBgColor || defaultStyles.iconBgColor;
  let iconColor = props.iconColor || defaultStyles.iconColor;
  let iconSize = props.iconSize || defaultStyles.iconSize;
  document.documentElement.style.setProperty(
    "--sidebar-icon-bg-color",
    iconBgColor
  );
  document.documentElement.style.setProperty(
    "--sidebar-title-color",
    titleColor
  );
  document.documentElement.style.setProperty(
    "--sidebar-title-hover-color",
    titleHoverColor
  );
  document.documentElement.style.setProperty(
    "--sidebar-title-active-color",
    titleActiveColor
  );

  const Header = (itemProps) => {
    if (props.headerComponent) {
      const element = React.cloneElement(props.headerComponent, {
        ...itemProps,
      });
      return <>{element}</>;
    }
    return <></>;
  };

  const SubMenu = ({ item, index }) => {
    let _iconSize = item.iconSize || iconSize;
    let _iconColor = item.iconColor || iconColor;
    const Icon = () => {
      const element = React.cloneElement(item.icon, {
        color: _iconColor,
        size: _iconSize,
      });
      return <>{element}</>;
    };

    if (!item.subMenu) {
      return (
        <ProSidebarMenuItem index={index} title={item.title} icon={<Icon />}>
          <Link to={item.url}> {item.title}</Link>
        </ProSidebarMenuItem>
      );
    }
    if (item.subMenu) {
      return (
        <ProSidebarSubMenu index={index} title={item.title} icon={<Icon />}>
          {item.subMenu.map((subMenu, subMenuIndex) => (
            <SubMenu key={subMenuIndex} item={subMenu}></SubMenu>
          ))}
        </ProSidebarSubMenu>
      );
    }
  };

  const Footer = (itemProps) => {
    if (props.headerComponent) {
      const element = React.cloneElement(props.footerComponent, {
        ...itemProps,
      });
      return <>{element}</>;
    }
    return <></>;
  };

  let isToggled = isCollapsible ? !open : open;
  return (
    <>
      <ProSidebar
        breakPoint="lg"
        collapsedWidth="80px"
        image={props.image}
        collapsed={isCollapsible ? open : false}
        toggled={open}
        onToggle={(val) => {
          setOpen(open ? false : true);
        }}
      >
        <SidebarHeader>
          <Box w="100%" h={headerHeight}>
            <Header />
          </Box>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape={iconShape}>
            {props.menu.map((menuItem, index) => {
              if (!menuItem.subMenu) {
                let _iconSize = menuItem.iconSize || iconSize;
                let _iconColor = menuItem.iconColor || iconColor;
                const Icon = () => {
                  const element = React.cloneElement(menuItem.icon, {
                    color: _iconColor,
                    size: _iconSize,
                  });
                  return <>{element}</>;
                };
                return (
                  <ProSidebarMenuItem
                    key={index}
                    title={menuItem.title}
                    icon={<Icon />}
                  >
                    <Link to={menuItem.url}> {menuItem.title}</Link>
                  </ProSidebarMenuItem>
                );
              }
              if (menuItem.subMenu) {
                let _iconSize = menuItem.iconSize || iconSize;
                let _iconColor = menuItem.iconColor || iconColor;
                const Icon = () => {
                  const element = React.cloneElement(menuItem.icon, {
                    color: _iconColor,
                    size: _iconSize,
                  });
                  return <>{element}</>;
                };
                return (
                  <ProSidebarSubMenu
                    key={index}
                    title={menuItem.title}
                    icon={<Icon />}
                  >
                    {menuItem.subMenu.map((subMenu, subMenuIndex) => (
                      <SubMenu
                        key={subMenuIndex}
                        index={subMenuIndex}
                        item={subMenu}
                      ></SubMenu>
                    ))}
                  </ProSidebarSubMenu>
                );
              }
            })}
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          <Box w="100%" h={footerHeight}>
            <Footer />
          </Box>
        </SidebarFooter>
      </ProSidebar>
      
    </>
  );
};
export default SideBar;
