import { gql } from "@apollo/client";

export const create_question_paper_mutation = gql`
  mutation createQuestionPaper($data: question_paper_input) {
    create_question_paper: createQuestionPaper(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_question_paper_mutation = gql`
  mutation updateQuestionPaper($data: question_paper_input, $id: String!) {
    update_question_paper: updateQuestionPaper(data: $data, id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;
export const schedule_question_paper = gql`
  mutation scheduleQuestionPaper(
    $question_paper_id: String!
    $publish_date: String!
  ) {
    scheduleQuestionPaper(
      question_paper_id: $question_paper_id
      publish_date: $publish_date
    ) {
      id
      status
      count

      error {
        message
        status_code
      }
    }
  }
`;

export const get_all_question_paper_query = gql`
  query getAllQuestionPapers(
    $page_number: Float
    $page_limit: Float
    $exam_id: String!
  ) {
    get_all_question: getAllQuestionPapers(
      page_number: $page_number
      page_limit: $page_limit
      exam_id: $exam_id
    ) {
      items {
        id
        title
        is_published
        allow_submission
        publish_date
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_question_paper_query = gql`
  query getQuestionPaper($id: String!) {
    get_question_paper: getQuestionPaper(id: $id) {
      id
      allowed_time
      title
      type
      sort_order
      dependent_paper {
        id
        title
      }
      question_paper_exam {
        id
        name
      }
      total_no_of_pages
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_question_paper_mutation = gql`
  mutation deleteQuestionPaper($id: String!) {
    delete_question_paper: deleteQuestionPaper(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const clear_user_test_submission = gql`
  mutation clearUserTestSubmission {
    clearUserTestSubmission {
      id
      status
      count
      error {
        status_code
        message
      }
    }
  }
`;
