import { gql } from "@apollo/client";

export const admin_dash_board_details_query = gql`
  query adminDashboardDetails {
    admin_dashboard_details: adminDashboardDetails {
      evaluated_paper_count
      submitted_count
      evaluator_assigned_paper_count
      question_paper_published_count
      question_paper_un_published_count
    }
  }
`;

export const admin_dashboard_query = gql`
  query getAdminDashboard {
    dashboard: getAdminDashboard {
      items {
        id
        object_array {
          exam_name
          question_papers {
            question_paper_title
            is_published
            publish_date
            question_paper_id
            question_paper_type
            counts {
              submitted
              total_count
              evaluation_started
              evaluated
            }
          }
        }
      }
    }
  }
`;
