import { gql } from "@apollo/client";

export const create_board_mutation = gql`
  mutation createBoard($data: board_input) {
    create_board: createBoard(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_board_mutation = gql`
  mutation updateBoard($data: board_input, $id: String!) {
    update_board: updateBoard(data: $data, id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_board_mutation = gql`
  mutation deleteBoard($id: String!) {
    delete_board: deleteBoard(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_board_query = gql`
  query gelAllBoard($page_number: Float, $page_limit: Float) {
    get_all_boards: getAllBoards(
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        name
        icon
        sort_order
        description
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_board_query = gql`
  query getBoard($id: String) {
    get_board: getBoard(id: $id) {
      id
      name
      sort_order
      error {
        status_code
        message
      }
    }
  }
`;
