import React, { useEffect, useState } from "react";
import { Box, Hidden, HStack, Text, VStack } from "native-base";
import { Button, Tabs, Form, Input, InputNumber, Slider } from "antd";
import fileUpload from "@helpers/file_upload";
import { FaRegComment } from "react-icons/fa";
const { TextArea } = Input;
import AntdModal from "@views/components/ui/antd_modal";

const Questions = (props) => {
  const {
    submission,
    submission_result,
    setCurrentPage,
    current_page,
    form,
    current_tab,
    setCurrentTab,
    setCurrentSection,
    current_section,
    questions,
    setQuestions,
    setInitialValues,
    canvas,
    onChange,
    sections,
    setSections,
    setJump,
  } = props;
  const [comment, setComment] = useState(false);
  const [current_section_id, setCurrentSectionId] = useState(false);
  const [question_nos, setQuestionsNos] = useState(false);
  const [index, setIndex] = useState(0);

  const handleSectionQuestion = (page_no) => {
    if (canvas?.current?.exportSvg)
      canvas?.current
        ?.exportSvg()
        .then(async (data) => {
          data = data.replace(
            `xlink:href=" "`,
            `xlink:href="https://aasc-assets-dev.s3.ap-south-1.amazonaws.com/submissions/${submission?.question_paper?.id}/${submission?.user?.id}/page_${current_page}.jpg"`
          );

          let url = await fileUpload(
            data,
            file_name,
            "correction",
            submission?.question_paper?.id,
            submission?.user?.id
          );
        })
        .catch((e) => {});
    if (canvas?.current?.clearCanvas) canvas?.current?.clearCanvas();
    setCurrentPage(page_no);
    let ques = [];
    submission?.question_paper?.question_paper_sections.map((y) =>
      y.section_questions.map((z) => {
        if (z.page_numbers.some((x) => x === page_no)) ques.push(z);
      })
    );
    let sectionId = ques[0]?.question_paper_section_id;
    setQuestions(ques);
    setQuestionsNos(ques.map((x) => x.number));
    setCurrentTab(sectionId);
    let section = submission?.question_paper?.question_paper_sections?.find(
      (x) => x.id === sectionId
    );
    setCurrentSectionId(section?.id);
    setCurrentSection(section);
  };
  useEffect(() => {
    handleSectionQuestion(current_page);
  }, [current_page]);
  useEffect(() => {
    if (submission?.question_paper?.id) {
      let tabs = [
        {
          label: "General",
          key: "general",
          children: " ",
        },
      ];
      submission.question_paper.question_paper_sections.map((x) => {
        let section = {
          label: x.name,
          key: x.id,
          children: " ",
        };
        let children = x.section_questions.map((y) => (
          <Button
            type={
              x.id === current_section_id &&
              question_nos?.some((x) => String(x) === String(y.number))
                ? "primary"
                : ""
            }
            onClick={() => {
              setJump(false);
              handleSectionQuestion(y.page_numbers[0]);
            }}
            style={{ margin: "3px" }}
          >
            {y.number}
          </Button>
        ));
        section.children = children;
        tabs.push(section);
      });
      setSections(tabs);
    }
  }, [submission, question_nos]);

  useEffect(() => {
    if (current_tab && current_tab !== "general" && !questions.length) {
      let section = submission?.question_paper?.question_paper_sections?.find(
        (x) => x.id === current_tab
      );
      setCurrentSection(section);
      setCurrentSectionId(section?.id);
      let sec_comment = null;
      if (section)
        sec_comment = submission_result?.user_section_evaluations?.find(
          (x) => x.question_paper_section_id === section.id
        );
      setInitialValues({
        id: sec_comment?.id,
        comment: sec_comment ? sec_comment.comment : "",
      });
    } else if (questions.length) {
      setInitialValues({
        evaluation: questions.map((question) => {
          let question_value = null;
          question_value = submission_result?.user_question_evaluations?.find(
            (x) => x.question_id === question.id
          );

          return {
            id: question_value ? question_value.id : "",
            mark: question_value ? question_value.mark : "",
            comment: question_value ? question_value.comment : "",
          };
        }),
      });
    } else {
      setInitialValues({
        id: submission_result?.user_paper_evaluations?.length
          ? submission_result.user_paper_evaluations[0].id
          : "",
        comment: submission_result?.user_paper_evaluations?.length
          ? submission_result.user_paper_evaluations[0].comment
          : "",
      });
    }
  }, [current_tab, submission_result]);

  return (
    <>
      <VStack>
        <Box>
          <Hidden till="lg">
            <Tabs
              defaultActiveKey="general"
              onChange={onChange}
              items={sections}
              activeKey={current_tab}
            />
          </Hidden>
          <Box>
            {current_tab === "general" || !current_tab ? (
              <>
                Please Add Comments for this Answer sheet
                <Form.Item label="Comment" name="comment">
                  <TextArea rows={2} />
                </Form.Item>
              </>
            ) : current_section?.name && !questions.length ? (
              <>
                Please Add Comments for {current_section.name}
                <Form.Item label="Comment" name="comment">
                  <TextArea rows={2} />
                </Form.Item>
              </>
            ) : current_section?.name && questions.length ? (
              questions.map((question, i) => (
                <HStack>
                  <Text mb={5} w="60vw">
                    {question.number}). {question.content}
                  </Text>

                  <Form.Item
                    name={["evaluation", i, "mark"]}
                    style={{ width: "15vw" }}
                  >
                    <InputNumber
                      style={{ width: "15vw" }}
                      min={0}
                      max={current_section.max_mark}
                      step="0.5"
                      placeholder="score"
                      inputMode="numeric"
                    />
                  </Form.Item>
                  <Form.Item
                    name={["evaluation", i, "mark"]}
                    style={{ width: "25vw" }}
                  >
                    <Slider min={0} max={current_section.max_mark} step="0.5" />
                  </Form.Item>
                  <Form.Item style={{ marginLeft: "7px" }}>
                    <Button
                      icon={<FaRegComment />}
                      onClick={() => {
                        setIndex(i);
                        setComment(true);
                      }}
                    ></Button>
                  </Form.Item>

                  {/* 
                  <Form.Item
                    label="Comment"
                    name={["evaluation", i, "comment"]}
                  >
                    <TextArea rows={2} />
                  </Form.Item> */}
                </HStack>
              ))
            ) : (
              ""
            )}
          </Box>
        </Box>
      </VStack>
      <AntdModal
        isOpen={comment}
        onClose={() => {
          setComment(false);
        }}
        width={450}
        header={"Comment"}
        component={
          <>
            <Form.Item label="Comment" name={["evaluation", index, "comment"]}>
              <TextArea rows={2} />
            </Form.Item>
            <HStack justifyContent={"flex-end"}>
              <Button onClick={() => setComment(false)}>Close</Button>
            </HStack>
          </>
        }
      />
    </>
  );
};
export default Questions;
