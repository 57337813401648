import { gql } from "@apollo/client";

export const get_all_responses_query = gql`
query getAllUserTestSubmissionsForQuestionPaper(
  $question_paper_id: String!
  $evaluator_id: String
  $status: String
  $page_number: Float
  $page_limit: Float
) {
  get_all_responses: getAllUserTestSubmissionsForQuestionPaper(
    question_paper_id: $question_paper_id
    evaluator_id: $evaluator_id
    status: $status
    page_number: $page_number
    page_limit: $page_limit
  ) {
    items {
      id
      status
      downloaded_at
      uploaded_at
      evaluated_at
      evaluator_id
      question_paper_id
      evaluator {
        id
        name
      }
      user {
        name
        id
        mobile_number
      }
      error {
        message
        message
      }
    }
    pagination{
      page_number
      page_limit
      total_count
    }
  }
}

`;
