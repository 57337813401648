import React, { useEffect, useState } from "react";
import { Box, Button, HStack, Text, VStack } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import {
  get_all_questions_query,
  dynamicRequest,
  useDynamicSelector,
  delete_question_mutation,
  dynamicClear,
} from "@services/redux";
import Wrapper from "@views/components/ui/wrapper";
import { Pagination, Popconfirm, Table, Typography } from "antd";
import { ROUTES } from "@views/routes/my_routes";
import AntdModal from "@views/components/ui/antd_modal";
import QuestionForm from "./question_details";
import { useHistory } from "react-router-dom";

const QuestionPaperList = (props) => {
  const { sectionId } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [actionItem, setActionItem] = useState({});
  const [current, setCurrent] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sectionType, setSectionType] = useState("");
  const { t } = useTranslation();
  const [questionModal, setQuestionModal] = useState(false);
  const [actionType, setActionType] = useState(null);
  const {
    items: get_all_questions,
    loading,
    pagination,
  } = useDynamicSelector("get_all_questions");
  console.log("get_all_questions", get_all_questions);
  const {
    loading: deleteQuestionLoading,
    status: deleteQuestionStatus,
    error: deleteQuestionError,
  } = useDynamicSelector("delete_question");
  const {
    loading: updateQuestionLoading,
    status: updateQuestionStatus,
    error: updateQuestionError,
  } = useDynamicSelector("update_question");
  const getAllQuestions = (page_number, page_limit) => {
    let keys = [{ key: "get_all_questions", loading: true }];
    let query = get_all_questions_query;
    let variables = {
      page_number: page_number || current,
      page_limit: page_limit || limit,
      section_id: sectionId,
    };
    dispatch(dynamicRequest(keys, query, variables));
  };
  useEffect(() => {
    if (deleteQuestionStatus === "success") {
      showToast({
        type: "success",
        message: `Question deleted successfully`,
      });
      dispatch(dynamicClear("delete_question"));
      getAllQuestions();
    }
    if (updateQuestionStatus === "success") {
      showToast({
        type: "Success",
        message: "Question Updated Successfully",
      });
      closeQuestionModal();
      dispatch(dynamicClear("update_question"));
      getAllQuestions();
    }
  }, [deleteQuestionStatus, updateQuestionStatus]);
  const handleEditQuestion = (item) => {
    console.log("handleEditQuestion", item);
    setActionItem(item);
    setSectionType(item.type);
    setActionType("edit");
    setQuestionModal(true);
  };

  const handleDeleteQuestionPaper = (record) => {
    let keys = [{ key: "delete_question", loading: true }];
    dispatch(
      dynamicRequest(keys, delete_question_mutation, {
        id: record?.id,
      })
    );
  };

  const closeQuestionModal = () => {
    setQuestionModal(false);
  };

  const fetchData = (page_number, page_limit) => {
    getAllQuestions(page_number, page_limit);
    setCurrent(page_number);
    setLimit(page_limit);
  };

  useEffect(() => {
    getAllQuestions();
  }, [sectionId]);

  let columns = [
    {
      title: t("table:Number"),
      dataIndex: "number",
      key: "number",
      sortable: false,
      align: "left",
      width: 50,
    },
    {
      title: t("table:Content"),
      dataIndex: "content",
      key: "content",
      sortable: false,
      align: "left",
      width: 600,
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      width: 50,

      render: (record) => {
        return (
          <span>
            <Typography.Link
              onClick={() => {
                handleEditQuestion(record);
              }}
              style={{ marginRight: 15 }}
            >
              <AiOutlineEdit />
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDeleteQuestionPaper(record)}
            >
              <AiOutlineDelete color="red" cursor={"pointer"} />
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  return (
    <>
      <Box top="10px" zIndex="2" bg="white">
        <Text fontSize="md" fontWeight="bold">
          {"Question List"}
        </Text>
      </Box>
      <Box width="100%" mt="30px" marginBottom="20px">
        <VStack space={5}>
          <Wrapper>
            <Table
              columns={columns}
              dataSource={get_all_questions}
              pagination={false}
              loading={loading}
            />
          </Wrapper>
          <Pagination
            total={pagination?.total_count}
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            onChange={fetchData}
          />
        </VStack>
        <Box>
          <AntdModal
            id="question"
            isOpen={questionModal}
            onClose={closeQuestionModal}
            width={"83vw"}
            noscroll={true}
            header={
              actionType === "add" ? t("Add Question") : t("Update Question")
            }
            component={
              <QuestionForm
                label={actionType === "add" ? t("add") : t("update")}
                close={closeQuestionModal}
                sectionId={sectionId}
                sectionType={sectionType}
                initialValues={actionItem}
              />
            }
            source={actionItem}
            size={"container"}
          />
        </Box>
      </Box>
    </>
  );
};
export default QuestionPaperList;
