import { gql } from "@apollo/client";

export const create_question_mutation = gql`
  mutation createQuestion($data: question_input) {
    create_question: createQuestion(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_questions_query = gql`
  query getAllSectionQuestions(
    $page_limit: Float
    $page_number: Float
    $section_id: String!
  ) {
    get_all_questions: getAllSectionQuestions(
      page_number: $page_number
      page_limit: $page_limit
      section_id: $section_id
    ) {
      items {
        id
        question_paper_section_id
        content
        ta_content
        number
        sort_order
        ta_answer_explanation
        answer_explanation
        topic_id
        no_of_answer_lines
        combined_question
        ta_combined_question
        question_options
        ta_question_options
        correct_answer_option
        type
        page_numbers
        objective_options {
          id
          option
          sort_order
          error {
            status_code
            message
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;


export const get_all_questions_without_pagination_query = gql`
  query getAllSectionQuestions(
    $page_limit: Float
    $page_number: Float
    $section_id: String!
  ) {
    get_all_questions: getAllSectionQuestions(
      page_number: $page_number
      page_limit: $page_limit
      section_id: $section_id
    ) {
      items {
        id
        question_paper_section_id
        content
        ta_content
        number
        sort_order
        ta_answer_explanation
        answer_explanation
        topic_id
        no_of_answer_lines
        combined_question
        ta_combined_question
        question_options
        ta_question_options
        correct_answer_option
        type
        page_numbers
        objective_options {
          id
          option
          sort_order
          error {
            status_code
            message
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_question_query = gql`
  query getQuestion($id: String!) {
    get_question: getQuestion(id: $id) {
      id
      question_paper_section_id
      content
      number
      sort_order
      topic_id
      ta_content
      no_of_answer_lines
      combined_question
      ta_combined_question
      ta_question_options
      type
      page_numbers
      objective_options {
        id
        option
        sort_order
        error {
          status_code
          message
        }
      }
    }
  }
`;

export const delete_question_mutation = gql`
  mutation deleteQuestion($id: String!) {
    delete_question: deleteQuestion(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_question_mutation = gql`
  mutation update_question($data: question_input, $id: String!) {
    update_question: updateQuestion(data: $data, id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
