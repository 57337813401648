import React from "react";
import { Box, Image } from "native-base";
import aimlogo from "@assets/icons/aim_logo.png";

const SidebarHeader = (props) => {
  return (
    <Box w={"100%"} h={"100%"} justifyContent="center" alignItems="center">
      <Image
        w="20"
        size="24"
        alt="logo"
        resizeMode={"contain"}
        source={aimlogo}
      />
      {/* <Image
       height="30px"
       width="100%"
        alt="logo"
        resizeMode={"contain"}
        source={"https://aasc-assets-dev.s3.ap-south-1.amazonaws.com/web-assets/nokkam+eng.png"}
      /> */}
    </Box>
  );
};

export default SidebarHeader;
