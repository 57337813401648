import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory, useParams } from "react-router-dom";
import Loader from "react-js-loader";
import {
  dynamicClear,
  dynamicRequest,
  get_one_submission_query,
  get_submission_results_query,
  useDynamicSelector,
  evaluation_ended_mutation,
  evaluation_started_mutation,
  create_section_comment_mutation,
  create_general_comment_mutation,
  create_question_evaluation_mutation,
} from "@services/redux";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Box, Center, Stack, Text } from "native-base";
import { Button, Row, Col, Form, Popconfirm } from "antd";
import EvaluatorHeaderBar from "@views/components/ui/header_bar/evaluator_header";
import { BiArrowBack } from "react-icons/bi";
import ImageRenderer from "./components/image_renderer";
import Questions from "./components/questions";
import fileUpload from "@helpers/file_upload";
import { navigate } from "@helpers/navigator";
import Confirmation from "./components/confirmation_model";
import AntdModal from "@views/components/ui/antd_modal";

const SubmissionDetails = (props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [current_page, setCurrentPage] = useState(1);
  const [current_section, setCurrentSection] = useState({});
  const [questions, setQuestions] = useState([]);
  const [initial_values, setInitialValues] = useState({});
  const [values, setValues] = useState({});
  const [current_tab, setCurrentTab] = useState("general");
  const canvas = React.createRef();
  const [form] = Form.useForm();
  const [sections, setSections] = useState([]);
  const [jump, setJump] = useState(false);
  const [confirmation_model, setConfirmationModel] = useState(false);
  useEffect(() => {
    form.setFieldsValue(initial_values);
  }, [initial_values]);
  const handleBack = () => {
    history.push(ROUTES.SUBMISSION_LIST);
  };
  const onChange = (key) => {
    setCurrentTab(key);
    if (current_tab === "general" && !initial_values.id) {
      let variables = {
        data: { user_test_submission_id: id, comment: values.comment },
      };
      let keys = [{ key: "create_general_comment", loading: true }];
      setInitialValues({ comment: values.comment });
      dispatch(
        dynamicRequest(keys, create_general_comment_mutation, variables, "M")
      );
    } else if (
      current_section?.name &&
      !questions.length &&
      !initial_values.id
    ) {
      let variables = {
        data: {
          user_answer_id: id,
          question_paper_section_id: current_section.id,
          comment: values.comment,
        },
      };
      let keys = [{ key: "create_section_comment", loading: true }];
      setInitialValues({ comment: values.comment });
      dispatch(
        dynamicRequest(keys, create_section_comment_mutation, variables, "M")
      );
    }
    setQuestions([]);
  };
  const submission = useDynamicSelector("get_submission");
  const submission_result = useDynamicSelector("get_submission_results");
  const { status: create_general_comment_status } = useDynamicSelector(
    "create_general_comment"
  );
  const { status: evaluation_ended_status, loading: evaluation_ended_loading } =
    useDynamicSelector("evaluation_ended");

  const { status: create_section_comment_status } = useDynamicSelector(
    "create_section_comment"
  );
  const { status: create_question_evaluation_status } = useDynamicSelector(
    "create_question_evaluation"
  );

  useEffect(() => {
    let keys = [{ key: "get_submission", loading: true }];
    dispatch(
      dynamicRequest(keys, get_one_submission_query, {
        id: id,
      })
    );
    keys = [{ key: "get_submission_results", loading: true }];
    dispatch(
      dynamicRequest(keys, get_submission_results_query, {
        id: id,
      })
    );
  }, [id]);
  useEffect(() => {
    if (create_general_comment_status === "success") {
      toast.success(`General Comment Submitted Successfully`);
      dispatch(dynamicClear("create_general_comment"));
      let keys = [{ key: "get_submission_results", loading: true }];
      dispatch(
        dynamicRequest(keys, get_submission_results_query, {
          id: id,
        })
      );
    } else if (create_section_comment_status === "success") {
      toast.success(`Section Comment Submitted Successfully`);
      dispatch(dynamicClear("create_section_comment"));
      let keys = [{ key: "get_submission_results", loading: true }];
      dispatch(
        dynamicRequest(keys, get_submission_results_query, {
          id: id,
        })
      );
    } else if (create_question_evaluation_status === "success") {
      // toast.success(`Question Evaluation Submitted Successfully`);
      dispatch(dynamicClear("create_question_evaluation"));
      let keys = [{ key: "get_submission_results", loading: true }];
      dispatch(
        dynamicRequest(keys, get_submission_results_query, {
          id: id,
        })
      );
    }
  }, [
    create_general_comment_status ||
      create_section_comment_status ||
      create_question_evaluation_status,
  ]);
  useEffect(() => {
    if (evaluation_ended_status === "success") {
      toast.success(`Submission Evaluated Successfully`);
      dispatch(dynamicClear("evaluation_ended"));
      navigate(ROUTES.SUBMISSION_LIST);
    } else if (evaluation_ended_status === "failure") {
      toast.error(`Something went wrong`);
    }
  }, [evaluation_ended_status]);
  const endEvaluation = () => {
    let variables = {
      id: id,
    };
    let keys = [{ key: "evaluation_ended", loading: true }];
    dispatch(dynamicRequest(keys, evaluation_ended_mutation, variables, "M"));
    setConfirmationModel(false);
  };
  const startEvaluation = () => {
    let variables = {
      id: id,
    };
    let keys = [{ key: "evaluation_started", loading: true }];
    dispatch(dynamicRequest(keys, evaluation_started_mutation, variables, "M"));
  };
  const handleSubmit = (values) => {
    let file_name = current_page;
    startEvaluation();

    if (canvas?.current?.exportSvg)
      canvas?.current
        ?.exportSvg()
        .then(async (data) => {
          data = data.replace(
            `xlink:href=" "`,
            `xlink:href="https://aasc-assets-dev.s3.ap-south-1.amazonaws.com/submissions/${submission?.question_paper?.id}/${submission?.user?.id}/page_${current_page}.jpg"`
          );

          let url = await fileUpload(
            data,
            file_name,
            "correction",
            submission?.question_paper?.id,
            submission?.user?.id
          );
        })
        .catch((e) => {});
    if (submission?.question_paper?.total_no_of_pages === current_page)
      setCurrentPage(1);
    else setCurrentPage(current_page + 1);
    if (canvas?.current?.clearCanvas) canvas?.current?.clearCanvas();
    if (current_section?.name && questions.length) {
      let variables = {
        data: values.evaluation.map((x, i) => {
          return {
            user_question_evaluation_id: initial_values?.evaluation?.[i]?.id,
            user_answer_id: id,
            question_id: questions[i].id,
            mark: x.mark || 0,
            comment: x.comment,
          };
        }),
      };
      let keys = [{ key: "create_question_evaluation", loading: true }];
      setInitialValues({
        evaluation: values.evaluation.map(() => {
          return { mark: "", comment: "" };
        }),
      });
      dispatch(
        dynamicRequest(
          keys,
          create_question_evaluation_mutation,
          variables,
          "M"
        )
      );
    }
  };
  const onValuesChange = (value, allValue) => {
    setValues(allValue);
  };
  return (
    <Box height={"99vh"} overflowY="auto">
      <EvaluatorHeaderBar />
      {submission.loading ? (
        <Center mt={"45vh"}>
          <Loader
            type="spinner-default"
            bgColor={"#349481"}
            color={"#349481"}
            size={50}
          />
        </Center>
      ) : (
        <>
          <Box mt={5}>
            <Row>
              <Col xs={24} sm={24} md={2}>
                <Button type="text" onClick={handleBack} icon={<BiArrowBack />}>
                  {"   "}
                  Back
                </Button>
              </Col>
              <Col xs={24} sm={24} md={20}>
                <Center>
                  <Text bold="true" fontSize={20}>
                    {submission?.question_paper?.title} -{" "}
                    {submission?.user?.name}
                  </Text>
                </Center>
              </Col>
              <Col xs={24} sm={24} md={2}>
                {" "}
              </Col>
            </Row>
            <Form
              form={form}
              name={"marks_and_comments"}
              layout={"vertical"}
              onFinish={handleSubmit}
              onValuesChange={onValuesChange}
              className="user_form ant-select-selector ant-select-selection-search"
            >
              <Stack
                direction={{
                  base: "column",
                  lg: "row",
                }}
                mt={3}
                space={3}
              >
                <Box pl={{ base: 0, lg: 10 }}>
                  <ImageRenderer
                    submission={submission}
                    current_page={current_page}
                    canvas={canvas}
                    form={form}
                    sections={sections}
                    onChange={onChange}
                    current_tab={current_tab}
                    jump={jump}
                    setJump={setJump}
                  />
                </Box>
                <Box w={{ base: "98vw", lg: "50vw" }} px={5}>
                  <Questions
                    submission={submission}
                    current_page={current_page}
                    setCurrentPage={setCurrentPage}
                    submission_result={submission_result}
                    canvas={canvas}
                    form={form}
                    setCurrentTab={setCurrentTab}
                    current_tab={current_tab}
                    setCurrentSection={setCurrentSection}
                    current_section={current_section}
                    questions={questions}
                    setQuestions={setQuestions}
                    setInitialValues={setInitialValues}
                    sections={sections}
                    setSections={setSections}
                    onChange={onChange}
                    setJump={setJump}
                  />
                </Box>
              </Stack>
            </Form>
          </Box>
          <Center
            mb={{ base: 5, xs: 12, md: 12, lg: 12 }}
            pb={{ base: 5, xs: 6, md: 12, lg: 6 }}
          >
            <Button
              type="primary"
              onClick={() => setConfirmationModel(true)}
              loading={evaluation_ended_loading}
            >
              {t("Finish Evaluation")}
            </Button>
          </Center>
        </>
      )}
      <AntdModal
        isOpen={confirmation_model}
        onClose={() => {
          setConfirmationModel(false);
        }}
        width={"90vw"}
        header={"Confirm Evaluation"}
        component={
          <Confirmation
            submission={submission}
            submission_result={submission_result}
            endEvaluation={endEvaluation}
          />
        }
      />
    </Box>
  );
};
export default SubmissionDetails;
