import { gql } from "@apollo/client";

export const create_question_paper_section_mutation = gql`
  mutation createQuestionPaperSection($data: question_paper_section_input) {
    create_question_paper_section: createQuestionPaperSection(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_all_question_paper_section_query = gql`
  query getAllQuestionPaperSections(
    $page_number: Float
    $page_limit: Float
    $question_paper_id: String!
  ) {
    get_all_question_paper_sections: getAllQuestionPaperSections(
      page_number: $page_number
      page_limit: $page_limit
      question_paper_id: $question_paper_id
    ) {
      items {
        id
        name
        ta_name
        type
        code
        note
        negative_mark
        no_of_answer_lines
        description
        max_mark
        sort_order
      }
    }
  }
`;

export const get_all_question_paper_without_pagination = gql`
  query getAllQuestionPaperSections(
    $page_number: Float
    $page_limit: Float
    $question_paper_id: String!
  ) {
    get_all_question_paper_sections_without_pagination: getAllQuestionPaperSections(
      page_number: $page_number
      page_limit: $page_limit
      question_paper_id: $question_paper_id
    ) {
      items {
        id
        name
        ta_name
        type
        code
        note
        negative_mark
        no_of_answer_lines
        description
        max_mark
        sort_order
        section_questions {
          id
          content
          number
          ta_content
          ta_question_options
          answer_explanation
          combined_question
          question_options
          correct_answer_option
        }
      }
    }
  }
`;

export const delete_question_paper_section_mutation = gql`
  mutation deleteQuestion($id: String!) {
    delete_question_paper_section: deleteQuestionPaperSection(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_question_paper_section_query = gql`
  query getQuestionPaperSection($id: String!) {
    get_question_paper_section: getQuestionPaperSection(id: $id) {
      id
      name
      code
      type
      note

      no_of_answer_lines
      description
      max_mark
      sort_order
      question_paper_id
      question_paper {
        id
        allowed_time
        title
        created_date_time
        total_no_of_pages
        subject_id
        is_deleted
        status
        total_submission_count
        question_paper_subject {
          id
          name
          error {
            status_code
            message
          }
        }
      }
    }
  }
`;

export const update_question_paper_section_mutation = gql`
  mutation updateQuestionPaperSection(
    $id: String!
    $data: question_paper_section_update
  ) {
    update_question_paper_section: updateQuestionPaperSection(
      id: $id
      data: $data
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
