import React, { useEffect } from "react";
import VForm from "@views/components/ui/antd_form";
import { Form, Button } from "antd";
import { useDispatch } from "react-redux";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useTranslation } from "react-i18next";
import { HStack } from "native-base";
import {
  create_text_book_subject_mutation,
  update_text_book_subject_mutation,
} from "@services/redux/slices/dynamic_entity/graphql/graphql_text_book_subject";

const SubjectDetails = (props) => {
  const { t } = useTranslation();
  const { initialValues, board_id, class_id } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const {
    status: createStatus,
    error: createError,
    loading: createLoading,
  } = useDynamicSelector("create_text_book_subject");
  const {
    status: updateStatus,
    error: updateError,
    loading: updateLoading,
  } = useDynamicSelector("update_text_book_subject");

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      ...initialValues,
      text_book_url: initialValues?.text_book_url
        ? [
            {
              uid: "-1",
              name: initialValues?.text_book_url ? "Text Book" : "",
              status: "done",
              url: initialValues?.text_book_url,
            },
          ]
        : [],
    });
  }, [initialValues]);

  const handleSubmit = (values) => {
    values.text_book_class_id = class_id;
    values.text_book_url = values.text_book_url?.[0]?.url;
    if (initialValues?.id) {
      let variables = {
        id: initialValues?.id,
        data: values,
      };
      let keys = [{ key: "update_text_book_subject", loading: true }];
      dispatch(
        dynamicRequest(keys, update_text_book_subject_mutation, variables, "M")
      );
    } else {
      let variables = {
        data: values,
      };
      let keys = [{ key: "create_text_book_subject", loading: true }];
      dispatch(
        dynamicRequest(keys, create_text_book_subject_mutation, variables, "M")
      );
    }
  };

  return (
    <Form
      form={form}
      name={"subject_details"}
      layout={"vertical"}
      onFinish={handleSubmit}
      className="user_form ant-select-selector ant-select-selection-search"
    >
      <VForm.TextBox
        label={t("Name")}
        field={"name"}
        rules={[
          {
            required: true,
            message: `${t("form:name_msg")}`,
          },
        ]}
      />
      <VForm.File
        label={t("Text Book")}
        field={"text_book_url"}
        rules={[
          {
            required: true,
            message: "Text Book is Required",
          },
        ]}
      />

      <HStack variant="submit_hstack">
        <VForm.Button
          isLoading={props.label === "Add" ? createLoading : updateLoading}
          disabled={false}
        >
          {t("Submit")}
        </VForm.Button>
        <Button onClick={props.close}>{t("close")}</Button>
      </HStack>
    </Form>
  );
};

export default SubjectDetails;
