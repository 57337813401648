import React from "react";
import { Card } from "antd";
import { Box, HStack, Text } from "native-base";
import { CiCircleRemove } from "react-icons/ci";
const SortableCardNotes = (props) => {
  const { item, handleRemoveNotes } = props;

  return (
    <Card style={{ cursor: "pointer" }}>
      <HStack space="3">
        <Box flex={1}>
          <Text>{item?.exam_note?.name || item?.name}</Text>
        </Box>
        <Box alignItems={"center"}>
          <CiCircleRemove
            size="25"
            color="red"
            onClick={() => {
              handleRemoveNotes(item);
            }}
          />
        </Box>
      </HStack>
    </Card>
  );
};
export default SortableCardNotes;
