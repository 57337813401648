import React from "react";
import {
  Form,
  Divider,
  Button,
  Select,
  Input,
  InputNumber,
  Checkbox,
} from "antd";
import { useDispatch } from "react-redux";
import {
  Box,
  HStack,
  VStack,
  Text,
  Pressable,
  useBreakpointValue,
} from "native-base";
import VForm from "@views/components/ui/antd_form";
import {
  dynamicRequest,
  useDynamicSelector,
  create_question_mutation,
  update_question_mutation,
} from "@services/redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useState } from "react";

function DynamicField(props) {
  const [form] = Form.useForm();
  const { initialValues, sectionId, sectionType, close } = props;
  console.log("initialValues", initialValues);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);

  const { t } = useTranslation();

  const {
    status: question_status,
    error: question_error,
    loading: question_Loading,
  } = useDynamicSelector("create_question");
  const {
    loading: deleteQuestionLoading,
    status: deleteQuestionStatus,
    error: deleteQuestionError,
  } = useDynamicSelector("delete_question");

  const {
    loading: updateQuestionLoading,
    status: updateQuestionStatus,
    error: updateQuestionError,
  } = useDynamicSelector("update_question");

  const handleSubmit = (values) => {
    console.log("handleSubmit", values);
    values.question_paper_section_id = sectionId;
    values.type = sectionType;
    if (typeof values.page_numbers === "string")
      values.page_numbers = values.page_numbers?.split(",");
    values.page_numbers = values.page_numbers?.map((x) => Number(x));
    if (initialValues?.id) {
      let variables = {
        id: initialValues?.id,
        data: values,
      };
      let keys = [{ key: "update_question", loading: true }];
      dispatch(dynamicRequest(keys, update_question_mutation, variables, "M"));
    } else {
      values.question_paper_section_id = sectionId;
      let variables = {
        data: values,
      };
      let keys = [{ key: "create_question", loading: true }];
      dispatch(dynamicRequest(keys, create_question_mutation, variables, "M"));
    }
  };
  useEffect(() => {
    if (updateQuestionStatus === "success") {
      close();
    }
  }, [updateQuestionStatus]);
  useEffect(() => {
    if (initialValues?.combined_question) {
      setChecked(true);
    }
    form.setFieldsValue(initialValues);
  }, [initialValues]);
  if (!initialValues?.id) form.resetFields();

  return (
    <Form
      form={form}
      name={"Add Question"}
      layout={"vertical"}
      onFinish={handleSubmit}
      className="user_form ant-select-selector ant-select-selection-search"
    >
      <Box>
        <Checkbox
          onChange={(e) => {
            setChecked(e.target.checked);
          }}
        >
          Is Combined Question
        </Checkbox>
        {checked ? (
          <VForm.Grid columns={1} spacingY={5} spacingX={10}>
            <VForm.HTMLEditor
              label={t("Combined Question")}
              field={"combined_question"}
              rules={[
                {
                  required: true,
                  message: "Combined Question is Required",
                },
              ]}
            />
            <></>
          </VForm.Grid>
        ) : (
          ""
        )}
        <VForm.Grid columns={1} spacingY={5} spacingX={10}>
          <VForm.HTMLEditor
            label={t("Content")}
            field={"content"}
            rules={[
              {
                required: true,
                message: "Content is Required",
              },
            ]}
          />
          <></>
        </VForm.Grid>
        {sectionType === "objective" ? (
          <VForm.Grid columns={2} spacingY={10} spacingX={10}>
            <VForm.HTMLEditor
              label={t("Option 1")}
              field={["question_options", 0]}
              rules={[
                {
                  required: true,
                  message: "Option 1 is Required",
                },
              ]}
            />
            <VForm.HTMLEditor
              label={t("Option 2")}
              field={["question_options", 1]}
              rules={[
                {
                  required: true,
                  message: "Option 2 is Required",
                },
              ]}
            />
            <VForm.HTMLEditor
              label={t("Option 3")}
              field={["question_options", 2]}
              rules={[
                {
                  required: true,
                  message: "Option 3 is Required",
                },
              ]}
            />
            <VForm.HTMLEditor
              label={t("Option 4")}
              field={["question_options", 3]}
              rules={[
                {
                  required: true,
                  message: "Option 4 is Required",
                },
              ]}
            />
          </VForm.Grid>
        ) : (
          ""
        )}
        <VForm.Grid columns={1} spacingY={5} spacingX={10}>
          <VForm.HTMLEditor
            label={t("answer_explanation")}
            field={"answer_explanation"}
          />
          <></>
        </VForm.Grid>
        {checked ? (
          <VForm.Grid columns={1} spacingY={5} spacingX={10}>
            <VForm.HTMLEditor
              label={t("Tamil Combined Question")}
              field={"ta_combined_question"}
              rules={[
                {
                  required: true,
                  message: "Tamil Question is Required",
                },
              ]}
            />
            <></>
          </VForm.Grid>
        ) : (
          ""
        )}
        <VForm.Grid columns={1} spacingY={5} spacingX={10}>
          <VForm.HTMLEditor
            label={t("Tamil Content")}
            field={"ta_content"}
            rules={[
              {
                required: true,
                message: "Tamil Content is Required",
              },
            ]}
          />
          <></>
        </VForm.Grid>
        {sectionType === "objective" ? (
          <VForm.Grid columns={2} spacingY={10} spacingX={10}>
            <VForm.HTMLEditor
              label={t("Tamil Option 1")}
              field={["ta_question_options", 0]}
              rules={[
                {
                  required: true,
                  message: "Option 1 is Required",
                },
              ]}
            />
            <VForm.HTMLEditor
              label={t("Option 2")}
              field={["ta_question_options", 1]}
              rules={[
                {
                  required: true,
                  message: "Option 2 is Required",
                },
              ]}
            />
            <VForm.HTMLEditor
              label={t("Option 3")}
              field={["ta_question_options", 2]}
              rules={[
                {
                  required: true,
                  message: "Option 3 is Required",
                },
              ]}
            />
            <VForm.HTMLEditor
              label={t("Option 4")}
              field={["ta_question_options", 3]}
              rules={[
                {
                  required: true,
                  message: "Option 4 is Required",
                },
              ]}
            />
          </VForm.Grid>
        ) : (
          ""
        )}
        <VForm.Grid columns={1} spacingY={5} spacingX={10}>
          <VForm.HTMLEditor
            label={t("tamil_answer_explanation")}
            field={"ta_answer_explanation"}
          />
          <></>
        </VForm.Grid>
        <VForm.Grid columns={3} spacingY={10} spacingX={10}>
          {sectionType === "objective" ? (
            <VForm.Select
              label={t("Correct Option")}
              field={"correct_answer_option"}
              options={[
                { id: 1, name: "1" },
                { id: 2, name: "2" },
                {
                  id: 3,
                  name: "3",
                },
                { id: 4, name: "4" },
              ]}
              rules={[
                {
                  required: true,
                  message: "Correct Option is Required",
                },
              ]}
            />
          ) : (
            ""
          )}
          <VForm.TextBox
            label={t("Number")}
            field={"number"}
            rules={[
              {
                required: true,
                message: "Number is Required",
              },
            ]}
          />
          <VForm.Number
            label={t("Sort Order")}
            field={"sort_order"}
            rules={[
              {
                required: true,
                message: "Sort Order is Required",
              },
            ]}
          />
          {sectionType === "objective" ? (
            ""
          ) : (
            <VForm.TextBox
              label={t("Page Numbers")}
              field={"page_numbers"}
              rules={[
                {
                  required: true,
                  message: "Page Numbers is Required",
                },
              ]}
            />
          )}
        </VForm.Grid>

        <HStack space="10" justifyContent={"flex-end"} marginRight="30px">
          <VForm.Button
            // loading={false}
            // disabled={false}
            loading={updateQuestionLoading || question_Loading}
            isLoading={updateQuestionLoading || question_Loading}
          >
            {t("Submit")}
          </VForm.Button>
          <Button onClick={props.close}>{t("close")}</Button>
        </HStack>
      </Box>
    </Form>
  );
}

export default DynamicField;
