import { gql } from "@apollo/client";
export const introspection_query = gql`
  query IntrospectionQuery {
    schema: __schema {
      types {
        name
        fields {
          name
        }
      }
    }
  }
`;
export const get_roles_query = gql`
  query getRoles {
    roles: getRoles {
      items {
        id
        name
      }
    }
  }
`;
export const create_role_mutation = gql`
  mutation createRole($data: role_input) {
    create_role: createRole(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;
export const update_role_mutation = gql`
  mutation updateRole($id: String!, $data: role_input) {
    update_role: updateRole(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;
export const delete_role_mutation = gql`
  mutation deleteRole($id: String!) {
    delete_role: deleteRole(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;
export const get_ui_modules_query = gql`
  query getUiModules {
    pages: getUiModules {
      items {
        id
        name
        page_components: ui_module_components {
          id
          name
          endpoints: api_modules {
            id
          }
          roles {
            id
          }
        }
        roles {
          id
        }
      }
    }
  }
`;
export const create_ui_module_mutation = gql`
  mutation createUiModule($data: ui_module_input) {
    create_page: createUiModule(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;
export const update_ui_module_mutation = gql`
  mutation updateUiModule($id: String!, $data: ui_module_input) {
    update_page: updateUiModule(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;
export const delete_ui_module_mutation = gql`
  mutation deleteUiModule($id: String!) {
    delete_page: deleteUiModule(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;
export const create_ui_module_component_mutation = gql`
  mutation createUiModuleComponent($data: ui_module_component_input) {
    create_page_component: createUiModuleComponent(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;
export const update_ui_module_component_mutation = gql`
  mutation updateUiModuleComponent(
    $id: String!
    $data: ui_module_component_update_input
  ) {
    update_page_component: updateUiModuleComponent(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;
export const link_endpoints_ui_module_component_mutation = gql`
  mutation updateUiModuleComponent(
    $id: String!
    $data: ui_module_component_update_input
  ) {
    link_endpoints_page_component: updateUiModuleComponent(
      id: $id
      data: $data
    ) {
      id
      status
      error {
        message
      }
    }
  }
`;
export const delete_ui_module_component_mutation = gql`
  mutation deleteUiModuleComponent($id: String!) {
    delete_page_component: deleteUiModuleComponent(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;
export const get_api_modules_query = gql`
  query getApiModules {
    endpoints: getApiModules {
      items {
        id
        name
      }
    }
  }
`;
export const sync_api_modules_mutation = gql`
  mutation syncApiModules($api_names: [String]) {
    sync_endpoints: syncApiModules(api_names: $api_names) {
      status
      error {
        message
      }
    }
  }
`;
export const create_api_module_mutation = gql`
  mutation createApiModule($data: api_module_input) {
    create_endpoint: createApiModule(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;
export const update_api_module_mutation = gql`
  mutation updateApiModule($id: String!, $data: api_module_input) {
    update_endpoint: updateApiModule(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;
export const delete_api_module_mutation = gql`
  mutation deleteApiModule($id: String!) {
    delete_endpoint: deleteApiModule(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;
export const set_ui_module_role_access_mutation = gql`
  mutation setUiModuleRoleAccess(
    $id: String
    $role_id: String
    $has_access: Boolean
  ) {
    setUiModuleRoleAccess(id: $id, role_id: $role_id, has_access: $has_access) {
      id
      status
      error {
        message
      }
    }
  }
`;
export const set_ui_module_component_role_access_mutation = gql`
  mutation setUiModuleComponentRoleAccess(
    $id: String
    $role_id: String
    $has_access: Boolean
  ) {
    setUiModuleComponentRoleAccess(
      id: $id
      role_id: $role_id
      has_access: $has_access
    ) {
      id
      status
      error {
        message
      }
    }
  }
`;
