import React, { useEffect } from "react";
import VForm from "@views/components/ui/antd_form";
import { Form, Button } from "antd";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  update_text_book_class_mutation,
  create_text_book_class_mutation,
  useDynamicSelector,
} from "@services/redux";
import { useTranslation } from "react-i18next";
import { HStack } from "native-base";

const ClassDetails = (props) => {
  const { t } = useTranslation();
  const { initialValues, board_id } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const {
    status: createStatus,
    error: createError,
    loading: createLoading,
  } = useDynamicSelector("create_text_book_class");
  const {
    status: updateStatus,
    error: updateError,
    loading: updateLoading,
  } = useDynamicSelector("update_text_book_class");

  useEffect(() => {
    let item = { ...initialValues, icon: initialValues?.icon?.[0]?.url };
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  const handleSubmit = (values) => {
    values.text_book_board_id = board_id;
    if (initialValues?.id) {
      let variables = {
        id: initialValues?.id,
        data: { ...values, icon: values?.icon?.[0]?.url },
      };
      let keys = [{ key: "update_text_book_class", loading: true }];
      dispatch(
        dynamicRequest(keys, update_text_book_class_mutation, variables, "M")
      );
    } else {
      let variables = {
        data: { ...values, icon: values?.icon?.[0]?.url },
      };
      let keys = [{ key: "create_text_book_class", loading: true }];
      dispatch(
        dynamicRequest(keys, create_text_book_class_mutation, variables, "M")
      );
    }
  };

  return (
    <Form
      form={form}
      name={"class_details"}
      layout={"vertical"}
      onFinish={handleSubmit}
      className="user_form ant-select-selector ant-select-selection-search"
    >
      <VForm.TextBox
        label={t("Name")}
        field={"name"}
        rules={[
          {
            required: true,
            message: `${t("form:name_msg")}`,
          },
        ]}
      />
      <VForm.File
        label={t("Icon")}
        field={"icon"}
        // rules={[
        //   {
        //     required: true,
        //     message: `${t("form:icon_msg")}`,
        //   },
        // ]}
      />
      <VForm.Number
        label={t("Sort Order")}
        field={"sort_order"}
        rules={[
          {
            required: true,
            message: `${t("form:sort_order_msg")}`,
          },
        ]}
      />
      <HStack variant="submit_hstack">
        <VForm.Button
          isLoading={props.label === "Add" ? createLoading : updateLoading}
          disabled={false}
        >
          {t("Submit")}
        </VForm.Button>
        <Button onClick={props.close}>{t("close")}</Button>
      </HStack>
    </Form>
  );
};

export default ClassDetails;
