import React from "react";
import { VStack } from "native-base";
import PageComponentItem from "./page_component_item";
const PageComponentList = (props) => {
  const { page_components } = props;
  return (
    <VStack>
      {page_components &&
        page_components.map((page_component, page_component_index) => (
          <PageComponentItem
            key={page_component_index}
            item={page_component}
            index={page_component_index}
          />
        ))}
    </VStack>
  );
};
export default PageComponentList;
